import React ,{useState,useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'
import { Table } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { getCookie } from "../ReactCookie";

function ContentBody (props){
    const [corp_count, setCorp_count] = useState(0);
    const [line_count, setLine_count] = useState(0);
    const [agent_count, setAgent_count] = useState(0);
    
    const history = useHistory();

    useEffect( () => {
        loadIntro();
    },[]);

    function loadIntro(){
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/stat/intro';

        axios.get(url_path
            ,{ 
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
            .then(res => { 
                if(res.data.res_code===200){
                    setCorp_count(res.data.data.corp_count);
                    setLine_count(res.data.data.line_count);
                    setAgent_count(res.data.data.agent_count);
    
                }else{
                    if(res.data.res_code===401){
                        history.push('/login');
                    }else{
                        alert(res.data.res_msg);
                    }
                }
            });

    }
    

    return(
        <>
            <div>
                <Table hover>
                    <tbody>
                        <tr style={{width: "100%"}}>
                            <td style={{width: "30%"}}>등록된 고객사</td><td>{corp_count} 건</td>
                            </tr>
                            <tr>
                            <td>등록된 회선수</td><td>{line_count} 건</td>
                            </tr>
                            <tr>
                            <td>등록된 Agent</td><td>{agent_count} 건</td>
                        </tr>
                    </tbody>
                </Table>  
            </div>
        </>
    );

}

export default ContentBody;