import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBriefcase} from "@fortawesome/free-solid-svg-icons";
import { NavItem, Nav } from "reactstrap";
import classNames from "classnames";

import { matchPath, Link, NavLink, useLocation, useHistory } from "react-router-dom";

function SideBar ({ isOpen, toggle }){
  const history = useHistory();
  const { pathname } = useLocation(); 

  const activestyle = {
        textDecoration: 'underline',
        color: 'white',
        backgroundColor: '#212529',
        display:'block',
        fontWeight:'700'
  }

  const SideList = () => {

    const listArr = [
      ["메인화면", "/main"],
      ["고객사목록", "/corps"],
      ["회선목록", "/lines"],
      ["통화녹취목록", "/records"],
      ["SMS목록", "/smss"],

      ["관리자목록", "/adms"],
      ["Agent목록", "/agents"],
      ["API목록", "/apis"],
      ["콜백URL목록", "/callbacks"],
      
      ["월별통계", "/stat_month"],
      ["고객사별통계", "/stat_corps"],

      ["로그목록", "/logs"],
      ["에러로그목록", "/errlogs"],

      ["고객사별 대표번호 인증처리", "/authcentrixs"],
    ]

    return (
      <Nav vertical className="list-unstyled pb-3">           
        {
          listArr && listArr.map((list, index) => 
            <div className="p-2" key={index}>
            { list[0] === "메인화면" ? <hr /> : <></> }
            <NavLink className="p-2" activeStyle={activestyle}  to={{              
                pathname: list[1],
                state: { pageName: list[0], nowPage : 0 },                
              
            }}
              /*
              isActive={(match, location) => {              
                    if(!location) return false;
                    const {pathname} = location;
                    console.log(pathname, list[1]);
                    return pathname === list[1];
              }}
              */
              isActive={(match, location) => {
                if (list[1].length > 1) {
                  return location.pathname.startsWith(list[1].slice(0, -1))
                } else {
                  return location.pathname===list[1];
                }                
              }
              }
            >
            <FontAwesomeIcon icon={faBriefcase} />
              <span className="ps-2">{list[0]}</span>
            </NavLink>            
              {list[0] === "메인화면" ? <hr /> : <></>}
              {list[0] === "SMS목록" ? <hr /> : <></>}
              {list[0] === "콜백URL목록" ? <hr /> : <></>}
              {list[0] === "고객사별통계" ? <hr /> : <></>}              
              {list[0] === "에러로그목록" ? <hr /> : <></>}              
            
            </div>
        )}
     </Nav>
    )
  }

  return(
  <div className={classNames("sidebar", { "is-open": isOpen })}>
    <div className="sidebar-header">
      <span color="info" onClick={toggle} style={{ color: "#fff" }}>
        &times;
      </span>
      <h3>IQ200 V2 Admin</h3>
    </div>
      <div className="side-menu">
        
          <SideList/>
        
    </div>
  </div>
  )
  }



export default SideBar;
