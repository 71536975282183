import { Input } from 'reactstrap';
import editImg from '../../assets/modify2.png';

function OneCallback({ item, handleEdit }) {
    
    item.checked=false;
    function onEdit(e){
        handleEdit(e,item.callback_idx);
    };

    function handleChecked(event){
        item.checked=!item.checked;
    }

    
    return (
        <tr style={{ cursor:'pointer', verticalAlign:'middle' }}>
            <th scope="row" style={{width: "2%"}}><Input type="checkbox" id="chk"  onClick={(e) => handleChecked(e)} /></th>
            <th style={{width: "5%"}}>{item.callback_idx}</th>
            <td onClick={(e) => onEdit(e)}>{item.callback_name}</td>
            <td onClick={(e) => onEdit(e)}>{item.callback_host}</td>
            <td onClick={(e) => onEdit(e)}>{item.callback_url}</td>
            <td onClick={(e) => onEdit(e)}>{item.callback_port}</td>            
            <td onClick={(e) => onEdit(e)}>{item.use_yn === "Y" ? "사용" : "사용안함"}</td>
            <td onClick={(e) => onEdit(e)}>{item.admin_id}</td>
            <td onClick={(e) => onEdit(e)}>{item.reg_date}</td>
            <td>
                <img src={editImg} alt="수정" onClick={(e) => onEdit(e)}></img>
            </td>
        </tr>
    );

}

export default OneCallback;