
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

function PageCount(props) {

    useEffect(() => {        
        props.setSearchData("nowPage", props.search.page);        
    }, [props.search.page]);   
    
    const isMobile = useMediaQuery({
        query: "(max-width: 575px)"
    })
    const isPC = useMediaQuery({
        query: "(min-width: 576px)"
    })

    
    return (
        <div>
            {isMobile &&
                <p className="text-end">
                    {/* 총 {search["total_count"]} 건 / 페이지 : ({search["page"]} / {search["total_page_cnt"]}) */}
                    총 {props.search.total_count} 건 / 페이지 : ({props.search.page} / {props.search.total_page_cnt})
                </p> 
            }
            {isPC &&
                <p className="text-end" style={{position: 'absolute', top:'72px', right: '20px'}}>
                    {/* 총 {search["total_count"]} 건 / 페이지 : ({search["page"]} / {search["total_page_cnt"]}) */}
                    총 {props.search.total_count} 건 / 페이지 : ({props.search.page} / {props.search.total_page_cnt})
                </p> 
            }
        </div>                
    );
}

export default PageCount;