import OneStatMonth from './OneStatMonth'
function StatMonthList({info  }){

    return (
        <tbody style={{display:'grid', gridTemplateColumns: '1fr'}}>
            { 
                info.map( stat =>{
                    return(
                       <OneStatMonth key={stat.stat_name} item={stat}  />
                    );
                })
            }
        </tbody>
    );

}

export default StatMonthList;