import { Input } from 'reactstrap';
import editImg from '../../assets/modify2.png';
import '../../css/Tables.css'

import {showPhone,showDate, replaceNumber} from '../../util/StrUtil';

function OneRecord({item  ,handleEdit}){
    item.checked=false;
    function onEdit(e){
        handleEdit(e,item.record_idx);
    };

    function handleChecked(event){
        item.checked=!item.checked;
    }

    
    return (
        <tr style={{verticalAlign: 'middle', padding:'3px'}}>
            <th scope="row" style={{width: "2%"}}><Input type="checkbox" id="chk"  onClick={(e) => handleChecked(e)} /></th>
            <th style={{width: "5%"}}>{item.record_idx}</th>
            <td style={{width: "10%"}} onClick={(e) => onEdit(e)}>{item.corp_name}</td>
            <td style={{width: "12%"}} onClick={(e) => onEdit(e)}>{item.line_name}</td>
            {/* <td style={{textAlign: "left"}}>{showDate(item.in_date+" "+item.in_time)}</td> */}
            <td style={{textAlign: "center", width: "12%"}}>{item.in_date+" "+item.in_time}</td>
            <td style={{width: "15%"}} onClick={(e) => onEdit(e)}>{replaceNumber(item.line_number)}</td>
            <td style={{width: "5%"}} onClick={(e) => onEdit(e)}>{item.local_number}</td>
            <td style={{width: "15%"}} onClick={(e) => onEdit(e)}>{replaceNumber(item.peer_number)}</td>
            <td style={{width: "5%"}}>{item.call_time}</td>
            <td style={{width: "8%"}}>{item.call_type}</td>
            <td style={{width: "8%"}}>{item.call_status}</td>
            <td style={{width: "5%"}}>
                <img src={editImg} alt="수정" onClick={(e) => onEdit(e)}></img>
            </td>
        </tr>
    );

}

export default OneRecord;