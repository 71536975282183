import React ,{useState, useEffect} from "react";
import axios from 'axios'

import { Table,Button } from 'reactstrap';

import AgentList from './AgentList'
import SearchAgent from './SearchAgent'
import PageNav from '../PageNav'
import PageCount from "../PageCount";

import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";

import RePagination from "../RePagination";
import '../../css/Tables.css';

const base_search={
    page: 1,
    limit: 20,
    total_count: 0,
    searchData: "",    
}

function AgentTable(props){
    const [search, setSearch] = useState(base_search);
    const [agents, setAgents] = useState([]);
    const history = useHistory();

    useEffect( () => {
        // searchList(1);
        if(history.location.state && history.location.state.nowPage !== 0 && history.location.state) searchList(history.location.state.searchOption.page, history.location.state.searchOption);
        else searchList(1);
    },[]);

    function searchList(num, options){

        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/agents/list';

        axios.get(url_path
            ,{ 
                params: {
                    page: num,
                    limit: search.limit,
                    searchData: options === undefined ? search.searchData : options.searchData,
                },
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => { 
            if(res.data.res_code===200){
                setSearch( 
                    {
                        ...search,
                        page: res.data.page,
                        limit: res.data.limit,
                        total_count:res.data.total_count,
                        searchData: res.config.params.searchData,
                    }
                );
                if(res.data.data===null){
                    setAgents([]);
                }else{
                    setAgents(res.data.data);
                }
                
                
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });
        
    }

    function handleEdit(ev,agent_idx) {
        let uri  = '/agent/'+agent_idx;
        //ev.preventDefault();
        // history.push(uri);
        props.history.push({pathname : uri, state: {searchOption: search}});
    }

    function handleRemove(){
       
      
        const arr_rem = [];
        agents.map(
            agent =>{
                if(agent.checked){
                    arr_rem.push(agent.agent_idx);
                }
                
            }
        );

        if(arr_rem.length===0){
            alert('체크박스를 선택해주세요');
            return;
        }

        if(!window.confirm('삭제하시겠습니까?')){
            return;
        }

        let json = {"agent_idx":arr_rem};        
        
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/agents/delete',json
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        ) 
        .then(response => { 
            if(response.data.res_code===200){
                searchList(search.page);

            }else{
                if(response.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(response.data.res_msg);
                }
                
            }
            
            
        });
    }

    function setSearchData(name,value){
        setSearch( 
            {
                ...search,
                [name]:value
            }
        );
    }



    return(
        <div style={{position: 'relative', height: 'calc(100% - 60px)'}}>
            <SearchAgent search={search} setSearchData={setSearchData} searchList={searchList} />
            <PageCount search={search} setSearchData={setSearchData}/>
            <Table hover className="agentTable">
                <thead>
                    <tr style={{width: "100%"}}>
                        <th style={{width: "2%"}} ></th>
                        <th style={{width: "5%"}} >idx</th>
                        <th style={{width: "10%"}}>회사명</th>
                        <th style={{width: "10%"}}>사용자명</th>
                        <th style={{width: "15%"}}>전화번호</th>
                        <th style={{width: "15%"}}>휴대폰</th>
                        <th style={{width: "10%"}}>이메일</th>
                        <th style={{width: "10%" }}>사용</th>
                        <th style={{width: "10%"}}>등록일자</th>					
                        <th style={{width: "5%"}}></th>  
                    </tr>
                </thead>
       
                <AgentList info={agents} handleEdit={handleEdit}/>
        

            </Table>
            <div className="paginationPosition">
                {agents.length === 0 ?
                    null
                :
                    <div style={{float: "left"}}>
                        <PageNav search={search} searchList={searchList} setSearchData={setSearchData}/>
                        <RePagination searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
                    </div>
                }

                <div style={{float: "right"}}>
                <Button color="secondary" size="sm" onClick={(e) => handleEdit(e,0)} >신규등록</Button>
                &nbsp;&nbsp;
                <Button color="secondary" size="sm" onClick={(e) => handleRemove()}> &nbsp;&nbsp;삭제&nbsp;&nbsp;  </Button>
                </div>
            </div>
        </div>
    );
}

export default AgentTable;