import React ,{useState, useEffect} from "react";
import axios from 'axios'
import { Table,Button } from 'reactstrap';

import ApiList from './ApiList'
import SearchApi from './SearchApi'
import PageNav from '../PageNav'
import PageCount from "../PageCount";

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { getCookie } from "../../ReactCookie";

import RePagination from "../RePagination";

const base_search={
    page: 1,
    limit: 20,
    total_count: 0,
    searchData: "",
    searchData2: "",    
    from: (new Date().setDate(new Date().getDate()-7)),
    to: new Date()
}

function ApiTable(props){
    const [search, setSearch] = useState(base_search);
    const [apis, setApis] = useState([]);
    const history = useHistory();

    useEffect( () => {
        // searchList(1);
        if(history.location.state && history.location.state.nowPage !== 0 && history.location.state) searchList(history.location.state.searchOption.page, history.location.state.searchOption);
        else searchList(1);
    },[]);

    function searchList(num, options){
       
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/apis/list';
        // url_path+="&limit="+search.limit;
        // url_path+="&searchData="+search.searchData;
        // url_path+="&searchData2="+search.searchData2;
        
        // if(search.from!==''){
        //     url_path+="&from="+moment(search.from).format('YYYY-MM-DD');
        // }
        // if(search.to!==''){
        //     url_path+="&to="+moment(search.to).format('YYYY-MM-DD');
        // }


        axios.get(url_path
            ,{ 
                params: {
                    page: num,
                    limit: search.limit,
                    searchData: options === undefined ? search.searchData : options.searchData,
                    searchData2: options === undefined ? search.searchData2 : options.searchData2,
                    from: moment(options === undefined ? search.from : options.from).format('YYYY-MM-DD'),
                    to: moment(options === undefined ? search.to : options.to).format('YYYY-MM-DD'),
                },
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => { 
            if(res.data.res_code===200){
                setSearch( 
                    {
                        ...search,
                        page: res.data.page,
                        limit: res.data.limit,
                        total_count:res.data.total_count,
                        searchData: res.config.params.searchData,
                        searchData2: res.config.params.searchData2,
                        from:  new Date(res.config.params.from),
                        to: new Date(res.config.params.to),
                    }
                );
                if(res.data.data===null){
                    setApis([]);
                }else{
                    setApis(res.data.data);
                }
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });
        
    }

    function handleEdit(ev,api_idx) {
        let uri  = '/api/'+api_idx;
        //ev.preventDefault();
        // props.history.push(uri);
        props.history.push({pathname: uri, state: {searchOption: search}});
    }

    function handleRemove(){
        
        const arr_rem = [];
        apis.map(
            api =>{
                if(api.checked){
                    arr_rem.push(api.api_idx);
                    //setRemoveIdx([...removeIdx, corp.corp_idx]);
                }
                
            }
        );

        if(arr_rem.length===0){
            alert('체크박스를 선택해주세요');
            return;
        }

        if(!window.confirm('삭제하시겠습니까?')){
            return;
        }

        let json = {"api_idx":arr_rem};        
        
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/apis/delete',json
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        ) 
        .then(response => { 
            if(response.data.res_code===200){
                searchList(search.page);
                
            }else{
                if(response.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(response.data.res_msg);
                }
                
            }
            
            
        });
    }

    function setSearchData(name,value){
        setSearch( 
            {
                ...search,
                [name]:value
            }
        );
    }

    function printExcel(){
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/xls/api/list'
        window.open(url_path,'_blank');
    }

    function dateFunc(mode, from, to) {

        const now = new Date();

        let month_start_day = new Date(now.getFullYear(), now.getMonth(), 1);
	    let month_last_day = new Date(now.getFullYear(), now.getMonth()+1, 0);

        let date_from = new Date(from);
        let date_to = new Date(to);
        
        if (mode === "prevDay") {            
            date_from = new Date(from).setDate(date_from.getDate() - 1);
            date_to = new Date(from).setDate(new Date(from).getDate() - 1);
        } else if (mode === "nowDay") {
            date_from = new Date().setDate(new Date().getDate());
            date_to = new Date().setDate(new Date().getDate());
        } else if (mode === "nextDay") {
            date_from = new Date(from).setDate(new Date(from).getDate() + 1);
            date_to = new Date(from).setDate(new Date(from).getDate() + 1);
        }       
                
        
        else if (mode === "prevMonth") {
            date_from = new Date(new Date(from).getFullYear(), new Date(from).getMonth() - 1, 1);
            date_to = new Date(new Date(from).getFullYear(), new Date(from).getMonth(), 0);            

        } else if (mode === "nowMonth") {
            date_from = month_start_day;
            date_to = month_last_day;

        } else if (mode === "nextMonth") {
            date_from = new Date(new Date(from).getFullYear(), new Date(from).getMonth() + 1, 1);
            date_to = new Date(new Date(from).getFullYear(), new Date(from).getMonth() +2, 0);            
        }

         setSearch({
                ...search,
                from: date_from,
                to: date_to
        });
    }


    return(
        <div style={{position: 'relative', height: 'calc(100% - 60px)'}}>
            <SearchApi search={search} setSearchData={setSearchData} searchList={searchList} dateFunc={dateFunc} />
            <PageCount search={search} setSearchData={setSearchData}/>
            <Table hover className="apiTable">
                <thead>
                <tr style={{width: "100%"}}>
                        <th style={{width: "2%"}} ></th>
                        <th style={{width: "5%"}}>idx</th>
                        <th style={{width: "10%"}}>고객사명</th>
                        <th style={{width: "15%"}}>전화번호</th>
                        <th style={{width: "5%"}}>내선</th>
                        <th style={{width: "15%"}}>상대방번호</th>
                        <th style={{width: "12%"}}>수신일</th> 
                        <th style={{width: "5%"}}>FROM</th>
                        <th style={{width: "5%"}}>타입</th>
                        
                    </tr>
                </thead>
       
                <ApiList info={apis} handleEdit={handleEdit}/>
        

            </Table>
            <div className="paginationPosition">
                {apis.length === 0?
                    null
                :
                    <div style={{float: "left"}}>
                        <PageNav search={search} searchList={searchList} setSearchData={setSearchData}/>
                        <RePagination searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
                    </div>
                }
                
                <div style={{float: "right"}}>
                
                <Button color="secondary" size="sm" onClick={(e) => handleRemove()}> &nbsp;&nbsp;삭제&nbsp;&nbsp;  </Button>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default ApiTable;

