import React ,{useState, useEffect} from "react";
import axios from 'axios'
import { Table } from 'reactstrap';

import StatMonthList from './StatMonthList'
import SearchStatMonth from './SearchStatMonth'
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { getCookie } from "../../ReactCookie";

import '../../css/Tables.css';

const base_search={
    corp_name: "",
    req_month: new Date()
}

function StatMonthTable(props){
    const [search, setSearch] = useState(base_search);
    const [stats, setStats] = useState([]);
    const history = useHistory();

    useEffect( () => {
        searchList();
    },[]);

    function searchList(){
       
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/stat/month?corp_name='+search.corp_name;
      
        if(search.req_month!==''){
            url_path+="&req_month="+moment(search.req_month).format('YYYY-MM');
        }      

        axios.get(url_path
            ,{ 
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => { 
            if(res.data.res_code===200){
                setStats(res.data.data);
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });
        
    }

    function setSearchData(name,value){
        setSearch( 
            {
                ...search,
                [name]:value
            }
        );
    }

    function dateFunc(mode, req_month) {
        const now = new Date();

        let month_start_day = new Date(now.getFullYear(), now.getMonth(), 1);

        let date_from = new Date(req_month);
        
        if (mode === "prevMonth") {
            date_from = new Date(new Date(req_month).getFullYear(), new Date(req_month).getMonth() - 1, 1);

        } else if (mode === "nowMonth") {
            date_from = month_start_day;

        } else if (mode === "nextMonth") {
            date_from = new Date(new Date(req_month).getFullYear(), new Date(req_month).getMonth() + 1, 1);
        }
         setSearch({
                ...search,
                req_month: date_from
        });
    }


    return(
        <div style={{height: `calc(100% - 90px)`}}>
            <SearchStatMonth dateFunc={dateFunc} search={search} setSearchData={setSearchData} searchList={searchList}/>
            <Table hover style={{marginBottom: '0'}}>
                <thead>
                    <tr style={{width: "100%"}}>
                        <th style={{width: "15%"}}>날짜</th>
                        <th style={{width: "15%"}}>신규회선수</th>
                        <th style={{width: "15%"}}>녹음파일수</th>
                        <th style={{width: "15%"}}>SMS</th>
                    </tr>
                </thead>
                {/* <StatMonthList info={stats} /> */}
            </Table>
            <Table style={{display: 'block', height:`calc(100% - 140px)`, overflowY: 'scroll', marginBottom:'0'}}>
                {/* { Number(month) < Number(item_month) ?
                    <tr style={{verticalAlign: 'middle', borderBottom: '1px solid #666'}}>
                        <td colSpan={4}>조회가능한 내역이 존재하지 않습니다.</td>
                    </tr>
                :
                } */}
                    <StatMonthList info={stats} />
            </Table>
            <br/>
            <br/>
        </div>
    )
}

export default StatMonthTable;

