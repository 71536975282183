import { Input } from 'reactstrap';
import editImg from '../../assets/modify2.png';

function OneAgent({item  ,handleEdit}){
    item.checked=false;
    function onEdit(e){
        handleEdit(e,item.agent_idx);
    };

    function handleChecked(event){
        item.checked=!item.checked;
    }

    
    return (
        <tr style={{verticalAlign: 'middle'}}>
            <th scope="row" style={{width: "2%"}}><Input type="checkbox" id="chk"  onClick={(e) => handleChecked(e)} /></th>
            <th style={{width: "5%"}}>{item.agent_idx}</th>
            <td style={{width: "10%"}} onClick={(e) => onEdit(e)}>{item.office_name}</td>
            <td style={{width: "10%"}} onClick={(e) => onEdit(e)}>{item.agent_name}</td>
            <td style={{width: "15%"}}>{item.office_number}</td>
            <td style={{width: "15%"}}>{item.mobile_number}</td>
            <td style={{width: "10%"}}>{item.email_addr}</td>
            <td style={{width: "10%"}}>{item.use_yn}</td>
            <td style={{width: "10%"}}>{item.reg_date}</td>
            <td style={{width: "5%"}}>
                <img src={editImg} alt="수정" onClick={(e) => onEdit(e)}></img>
            </td>
        </tr>
    );

}

export default OneAgent;