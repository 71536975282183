import { Button, InputGroup, ButtonGroup, Input, FormGroup, Col, Label } from 'reactstrap';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../../css/search.css"
import {useLocation} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


function SearchRecord(props) {
    
    let location = useLocation();


    const handleSearch = (e)  => {
        props.searchList(1);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            handleSearch(e); 
        } 
    }

    const dateFunc = (mode) => {
         props.dateFunc(mode, props.search.from, props.search.to);        
    }


    return (
         <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{marginBottom: '20px'}}><h3>통화녹취목록</h3></Col>
            <Col sm={12} >
                <div className="date_picker_div" style={{ display: 'flex' , flexFlow:'row nowrap' , width:'100%' , justifyContent:'flex-start' }}>
                    <DatePicker className="date_picker form-control" style={{ width:'150px' }} dateFormat="yyyy-MM-dd" id='from' selected={props.search.from} onChange={(date) => props.setSearchData("from", date)} />
                        <span style={{lineHeight: '33px'}}>&nbsp; ~ &nbsp;</span>
                    <DatePicker className="date_picker form-control" style={{ width:'150px' }} dateFormat="yyyy-MM-dd" id='to' selected={props.search.to} onChange={(date) => props.setSearchData("to", date)} />
                    
                    <ButtonGroup className="ms-1" style={{ whiteSpace:'nowrap' }}>
                        <Button type="button" onClick={(e) => dateFunc('prevDay')}>«</Button>
                        <Button type="button" onClick={(e) => dateFunc('nowDay')}>당일</Button>
                        <Button type="button" onClick={(e) => dateFunc('nextDay')}>»</Button>
                    </ButtonGroup>

                    <ButtonGroup className="ms-1" style={{ whiteSpace:'nowrap' }}>
                        <Button type="button" onClick={(e) => dateFunc('prevMonth')}>«</Button>
                        <Button type="button" onClick={(e) => dateFunc('nowMonth')}>당월</Button>
                        <Button type="button" onClick={(e) => dateFunc('nextMonth')}>»</Button>
                    </ButtonGroup>
                    &nbsp;&nbsp;&nbsp;&nbsp;                
                    <InputGroup className="ms-1" style={{ width:'auto' }}>
                        <div style={{position: 'relative', display: 'flex'}}>
                            <Input type="text" className="search_box" id="searchData" value={props.search.searchData} placeholder="고객사정보" onChange={(e) => props.setSearchData("searchData",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
                            {
                                props.search.searchData.length ?
                                    <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("searchData", '')} />
                                : null
                            }
                        </div>
                        <div style={{position: 'relative',  display: 'flex'}}>
                            <Input type="text" className="search_box" id="searchData2" value={props.search.searchData2} placeholder="상대방번호" onChange={(e) => props.setSearchData("searchData2",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
                            {
                                props.search.searchData2.length ?
                                    <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("searchData2", '')} />
                                : null
                            }
                        </div>

                        <div style={{display: 'flex'}}>
                            <Input type="select" className="search_box" id="key1" value={props.search.key1} onChange={(e) => props.setSearchData("key1", e.target.value)} onKeyPress={(e) => handleKeyPress(e)}>
                                <option value="" >착발신</option>
                                <option value="in" >IN</option>
                                <option value="out" >OUT</option>
                            </Input>
                        </div>
                        <div style={{display: 'flex'}}>
                            <Input type="select" className="search_box" id="key2" value={props.search.key2} onChange={(e) => props.setSearchData("key2", e.target.value)} onKeyPress={(e) => handleKeyPress(e)}>
                                <option value="" >상태</option>
                                <option value="OK" >OK</option>
                                <option value="FAIL" >FAIL</option>
                                <option value="NO-ANS" >NO-ANS</option>
                                <option value="BUSY" >BUSY</option>                            
                            </Input>
                        </div>
                        <Button className="btn" onClick={(e) => handleSearch(e)} >
                            검색
                        </Button>
                    </InputGroup> 
                    <div style={{marginLeft: '15px', marginRight: '5px', borderLeft:'1px solid #c3c3c3'}}>
                        <p style={{marginLeft: '15px', fontSize: '14px', fontWeight: 500, color: '#3b4b64', lineHeight: '35.5px'}}>내선통화</p>
                    </div>
                    <div className='extensionCalls-toggle'>
                        <Input type="radio" id='includeExtensionCalls' name="kind_yn" value='' checked={props.search.kind_yn === ''} onChange={(e) => props.setSearchData("kind_yn", e.target.value)} />
                        <Label for='includeExtensionCalls'>포함</Label>
                        <Input type="radio" id='withoutExtensionCalls' name="kind_yn" value='N' checked={props.search.kind_yn === 'N'} onChange={(e) => props.setSearchData("kind_yn", e.target.value)} />
                        <Label for='withoutExtensionCalls'>제외</Label>
                    </div>
                </div>
            </Col>
        </FormGroup>

         
        // <div style={{float: "right" ,padding: "10px"}}>
        //     <div className="date_picker_div">
        //     <DatePicker className="date_picker" dateFormat="yyyy-MM-dd" id='from' selected={props.search.from} onChange={(date) => props.setSearchData("from",date)} />
        //     </div>
        //     <div style={{float: "left"}}> ~ </div>
        //      <div className="date_picker_div">
        //      <DatePicker className="date_picker" dateFormat="yyyy-MM-dd" id='to' selected={props.search.to} onChange={(date) => props.setSearchData("to",date)} />
        //      </div>
            
           
           
        //      <input type="text" className="search_box" id="peer_number"  value={props.search.peer_number} placeholder="상대방번호" onChange={(e) => props.setSearchData("peer_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>

        //     <input type="text" className="search_box" id="line_number"  value={props.search.line_number} placeholder="전화번호" onChange={(e) => props.setSearchData("line_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
          
        //    <input type="text" className="search_box" id="local_number"  value={props.search.local_number} placeholder="내선번호" onChange={(e) => props.setSearchData("local_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
           

        //     <input type="text" className="search_box" id="corp_name"  value={props.search.corp_name} placeholder="고객사명" onChange={(e) => props.setSearchData("corp_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
           
        //    <Button color="secondary" size="sm" onClick={(e) => handleSearch(e)} > &nbsp;&nbsp;검색&nbsp;&nbsp;  </Button>
        // </div>

    );
}

export default SearchRecord;