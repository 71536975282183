import { Button, InputGroup, Input, FormGroup, Col } from 'reactstrap';
import "../../css/search.css"
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function SearchAdmin(props) {
    
    let location = useLocation();

    const handleSearch = (e)  => {
        props.searchList(1);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            handleSearch(e); 
        } 
      }

    return (
        <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{marginBottom: '20px'}}><h3>관리자목록</h3></Col>
            <Col sm={3}>
                <InputGroup>
                    <div style={{position: 'relative', display: 'flex'}}>
                        <Input type="text" className="search_box form-control" id="admin_id"  value={props.search.admin_id} placeholder="아이디" onChange={(e) => props.setSearchData("admin_id",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>          
                        {
                            props.search.admin_id.length ?
                                <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("admin_id", '')} />
                            : null
                        }
                    </div>
                    <div style={{position: 'relative', display: 'flex'}}>
                        <Input type="text" className="search_box form-control" id="admin_name"  value={props.search.admin_name} placeholder="관리자명" onChange={(e) => props.setSearchData("admin_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>                    
                        {
                            props.search.admin_name.length ?
                                <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("admin_name", '')} />
                            : null
                        }
                    </div>
                    <Button onClick={(e) => handleSearch(e)} >
                    검색
                    </Button>
                </InputGroup>                    
                {/* <input  />           
                <Button color="secondary" className="btn" size="sm" onClick={(e) => handleSearch(e)} >검색</Button> */}
            </Col>
        </FormGroup>
        
        // <div style={{float: "right" ,padding: "10px"}}>           
        //     <input type="text" className="search_box" id="admin_id" value={props.search.admin_id} placeholder="아이디" onChange={(e) => props.setSearchData("admin_id", e.target.value)} onKeyPress={(e) => handleKeyPress(e)} />
        //     <input type="text" className="search_box" id="admin_name" value={props.search.admin_name} placeholder="관리자명" onChange={(e) => props.setSearchData("admin_name", e.target.value)} onKeyPress={(e) => handleKeyPress(e)} />          
        //     <Button color="secondary" size="sm" onClick={(e) => handleSearch(e)} > &nbsp;&nbsp;검색&nbsp;&nbsp;  </Button>
        // </div>

    );
}

export default SearchAdmin;
