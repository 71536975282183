import { useEffect } from 'react';
import OneStatCorp from './OneStatCorp'


function StatCorpList({info}){

    const hasScrollbar = () => {
        const tablebody = document.querySelector('tbody');
        if(info.length*29 <= tablebody.clientHeight) tablebody.style.width = '100%'
        else tablebody.style.width = 'calc(100% + 10px)'
    }

    useEffect(() => {
        hasScrollbar();
        window.addEventListener('resize', hasScrollbar);
        return () => {
            window.removeEventListener('resize', hasScrollbar);
        }
    }, [info])

    return (
        <tbody>
            {
                info.map( stat =>{
                    return(
                       <OneStatCorp key={stat.stat_name} item={stat}  />
                    );
                })
            }
        </tbody>
    );

}

export default StatCorpList;