import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import {getCookie} from '../ReactCookie'

import { withRouter } from 'react-router-dom'
import { confirmAlert } from "react-confirm-alert";


const Topbar = ({ toggleSidebar ,logoutFunc}) => {
  const [topbarIsOpen, setTopbarOpen] = useState(true);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);

  //const [corp_name,SetCorp_name] = useState("");
  let login_name = getCookie("login_name");

  function logoutPage(){
    
    confirmAlert({
        //title: "등록된 콜백URL 을 삭제하시겠습니까?\n되돌릴수 없습니다.",
        message: "정말 로그아웃 하시겠습니까?",
        buttons: [
            {
            label: "예",
                onClick: () => logoutFunc()
            },
            {
            label: "아니오"
            // onClick: () => alert("Click No")
            }
        ]
    });
  }

  
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <div className="container-fluid navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2 ms-2">        
        <Button color="secondary" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faAlignLeft} />
        </Button>
        {/* <NavbarToggler onClick={toggleTopbar} /> */}
        <form className="d-flex">
          <font style={{ color:'#aaaaaa', lineHeight: '33px', marginRight: '10px' }} >{login_name}님 로그인하셨습니다.</font>
          <Button type="button" className="btn btn-sm ms-2" onClick={() => logoutPage()} >로그아웃</Button>
        </form>
      </div>
  </nav>
  );
};

export default withRouter(Topbar);
