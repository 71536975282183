import {useState, useEffect} from "react";
import { Form, FormGroup, Label, Input, Col ,Button } from 'reactstrap';
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";

const blank_data={
    sms_idx: 0,
    corp_name: "",
    line_numer: "",
    local_number: "",
    peer_number: "",
    sms_date: "",
    sms_data: "",
    reg_date: "",
    checked: false

}

function SmsDetail(props){
    const [ data, setData ] = useState(blank_data);
    const history = useHistory();

    useEffect(() => {
        const historyState = history.block((location, action) => {
            if (action === 'POP'){
                return goList();
            }
            return true;
        })
        return () => historyState();
    }, [])

    useEffect( () => {
        let uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/sms/'+props.match.params.sms_idx;

        axios.get(uri
            ,{ 
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => { 
            if(res.data.res_code===200){
                setData(res.data.data);
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });

    },[]);

    function valueChange(e){
        setData( 
            {
                ...data,
                [e.target.name]:e.target.value
            }
        );
    }
    
    function goList(){
        // history.goBack();
        history.push({
            pathname: '/smss',
            state: {...props.location.state}
        })
    }


    return(
        <div style={{paddingTop: '15px' ,paddingBottom: '15px'}}>
        <Form>
            <FormGroup row>
            <Label for="sms_idx" sm={2}>색인</Label>
            <Col sm={10}>
            <Input type="text"  name="sms_idx" id="sms_idx" value={data.sms_idx} readOnly="readonly" placeholder="색인번호입니다" onChange={(e) => valueChange(e)}/>
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="corpName" sm={2}>고객사명</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="corp_name" id="corp_name" readOnly="readonly" value={data.corp_name} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="line_number" sm={2}>전화번호</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="line_number" id="line_number" readOnly="readonly" value={data.line_number} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="local_number" sm={2}>내선번호</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="local_number" id="local_number" readOnly="readonly" value={data.local_number} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>


            <FormGroup row>
            <Label for="peer_number" sm={2}>상대방번호</Label>
            <Col sm={10}>
            <Input type="text" name="peer_number" id="peer_number"  value={data.peer_number} readOnly="readonly" placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="sms_date" sm={2}>수신일</Label>
            <Col sm={10}>
            <Input type="text" name="sms_date" id="sms_date" value={data.sms_date} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="sms_data" sm={2}>SMS</Label>
            <Col sm={10}>
            <Input type="textarea" name="sms_data" id="sms_data" value={data.sms_data} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>



            <p>
            </p>
            
            <FormGroup check row style={{textAlign: "right"}}>
                <Col sm={{ size: 10, offset: 2 }}>
                
                <Button onClick={() => goList()}>목록으로</Button>
                </Col>
            </FormGroup>
        </Form>
        </div>
    );
}

export default SmsDetail;