import React, { useEffect } from "react";
import OneAuthCentrix from "./OneAuthCentrix";


const AuthCentrixList = ({ info, search, searchList, handleEdit ,page, limit, checkedline, setCheckedline }) => {

  const hasScrollbar = () => {
    const tablebody = document.querySelector('tbody');
    if(info.length*35 <= tablebody.clientHeight) tablebody.style.width = '100%'
    else tablebody.style.width = 'calc(100% + 10px)'
  }

  useEffect(() => {
    hasScrollbar();
    window.addEventListener('resize', hasScrollbar);
    return () => {
        window.removeEventListener('resize', hasScrollbar);
    }
  }, [info])
    
  return (   
    <tbody>
      {info.length === 0?
        <tr>
            <td colSpan='12'>고객사 정보가 존재하지 않습니다.</td>
        </tr>
      :
        info.map((corp, index) => {
            return(
              <OneAuthCentrix
                key={corp.line_idx}
                corp_idx={corp.corp_idx}
                total_count={search.total_count}
                array_count={index}
                page={page}
                limit={limit}
                item={corp}
                count={info.length}
                handleEdit={handleEdit}
                searchList={searchList}
                checkedline={checkedline} setCheckedline={setCheckedline}
              />
            );
        })
      }
    </tbody>
  );
};

export default AuthCentrixList;
