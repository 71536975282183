import {useState, useEffect} from "react";
import { Form, FormGroup, Label, Input, Col, Button } from 'reactstrap';
import Select from 'react-select';
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";
import { confirmAlert } from "react-confirm-alert";

const blank_data={
    callback_idx: 0,
    callback_name: "",
    callback_host: "",
    callback_url: "",
    callback_port: "",
    callback_update : "N",
    use_yn: "Y",
    admin_id: getCookie("login_id"),
    reg_date:""
}

function CallbackDetail(props) {
    
    const [data, setData] = useState(blank_data);    

    const [callback_data, setCallback_data] = useState([]);

    const history = useHistory();
    useEffect( () => {

        if(props.match.params.callback_idx>0){

            let uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/callbacks/'+props.match.params.callback_idx;

            axios.get(uri
                ,{ 
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getCookie("access_token")
                    }
                }
                ) 
            .then(res => { 
                if (res.data.res_code === 200) {
                     setData({
                            ...res.data.data,
                            admin_id:getCookie("login_id"),
                    });
                    
                }else{
                    setData(blank_data);
                }
            });
        }
        

    }, []);
    

    function valueChange(e){
        setData(
            {
                ...data,                
                [e.target.name]:e.target.value
            }
        );
    }

    function handleUpdate() {

        //수정일때
        if (data.callback_idx > 0) {
            axios.put(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/callback/'+ data.callback_idx , data ,{
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ getCookie("access_token")
                }
            }).then(response => {     
                if(response.data.res_code===200){
                    //setData(blank_data );
                    //response.data.data.name= name;
                    setData(response.data.data);
                    confirmAlert({message: "Callback 정보를 수정하였습니다.", buttons: []})
                    setTimeout(() => {
                        if(document.querySelector('.react-confirm-alert-overlay')) document.querySelector('.react-confirm-alert-overlay').click();
                    }, 1200)  
                }else{
                    alert(response.data.res_msg);
                }
            });
                
        //신규등록일때
        } else { 
            axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/join/callback' , data ,{
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ getCookie("access_token")
                }
            }).then(response => {
                if(response.data.res_code===200){
                    //setData(blank_data );
                    //response.data.data.name= name;
                    setData(response.data.data);
                    confirmAlert({message: "새로운 Callback 정보를 등록하였습니다.", buttons: []})
                    setTimeout(() => {
                        if(document.querySelector('.react-confirm-alert-overlay')) document.querySelector('.react-confirm-alert-overlay').click();
                    }, 1200)  
                }else{
                    alert(response.data.res_msg);
                }
            });
        }
    }
    
    function goList(){
        history.goBack();
    }

    return(
        <div style={{paddingTop: '15px' ,paddingBottom: '15px'}}>
            <Form>
           
            <FormGroup row className="mb-1">
            <Label for="callback_idx" sm={2}>색인</Label>
            <Col sm={10}>
            <Input type="text"  name="callback_idx" id="callback_idx" value={data.callback_idx|| ''} readOnly="readonly" placeholder="색인번호입니다" onChange={(e) => valueChange(e)}/>
            </Col>
            </FormGroup>
                
            <FormGroup row className="mb-1">
            <Label for="callback_name" sm={2}>Callback 이름</Label>
            <Col sm={4}>
            <Input type="text" style={{imeMode:false}} name="callback_name" id="callback_name" value={data.callback_name|| ''} placeholder="개발기/테스트기" onChange={(e) => valueChange(e)} />
            </Col>          
            </FormGroup>        
                

            <FormGroup row className="mb-1">
            <Label for="callback_host" sm={2}>Callback IP주소(*)</Label>
            <Col sm={4}>
            <Input type="text" style={{imeMode:false}} name="callback_host" id="callback_host" value={data.callback_host|| ''} placeholder="1.212.9.203" onChange={(e) => valueChange(e)} />
            </Col>                               
            
            <Label for="callback_url" sm={2}>Callback URL(*)</Label>
            <Col sm={4}>
            <Input type="text" style={{imeMode:false}} name="callback_url" id="callback_url" value={data.callback_url|| ''} placeholder="/callback/callback.jsp" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            
            <FormGroup row className="mb-1">
            <Label for="callback_port" sm={2}>Callback 포트(*)</Label>
            <Col sm={4}>
            <Input type="text" style={{imeMode:false}} name="callback_port" id="callback_port" value={data.callback_port|| ''} placeholder="8080" onChange={(e) => valueChange(e)} />
            </Col>            
            </FormGroup>
            

            <FormGroup row className="mb-1">
            <Label for="use_yn" sm={2}>사용여부</Label>
            <Col sm={4}>
            <Input type="select" name="use_yn" id="use_yn" value={data.use_yn|| ''} onChange={(e) => valueChange(e)}>
                    <option value="Y" >사용</option>
                    <option value="N" >사용안함</option>
            </Input>
            </Col>
            <Label for="registerDay" sm={2}>등록일</Label>
            <Col sm={4}>
            <Input type="text" name="reg_date" id="reg_date" value={data.reg_date || ''} readOnly="readonly" placeholder="등록일입니다" onChange={(e) => valueChange(e)} />
            
            <Input type="hidden" name="admin_id" id="admin_id" value={data.admin_id || getCookie("login_id")}/>
            </Col>
                </FormGroup>
                
                
            <FormGroup row className="mb-1">
            <Label for="callback_update" sm={2}>기존 데이터 업데이트(*)</Label>
            <Col sm={4}>
                <Input type="select" name="callback_update" id="callback_update" value={data.callback_update|| ''} onChange={(e) => valueChange(e)}>
                        <option value="N" >미진행</option>
                        <option value="Y" >진행</option>
                </Input>
                        <p style={{ color: 'red', fontSize: '.825rem' }}>※주의. 진행을 선택하고 수정하실 경우 해당 콜백URL idx 로 등록되어있던 회선들의 콜백URL 정보를 바뀐 데이터로 모두 업데이트 시킵니다.</p>
                        
            </Col>            
            </FormGroup>
                
            <FormGroup check row className="mb-1">
               {/* <Col sm={{ size: 10, offset: 2 }}> */}
                <Col sm={12} className="text-end">
                <Button onClick={() => handleUpdate()}>
                    { data.callback_idx > 0 ? "수정" : "등록"}
                </Button>
                <Button className="ms-2" onClick={() => goList()}>목록</Button>
                </Col>
            </FormGroup>
        </Form>
        </div>
    );
}

export default CallbackDetail;