import React, { useEffect } from "react";
import OneCorp from "./OneCorp";

const CorpList = ({ info, search, handleEdit ,page, limit }) => {

    const hasScrollbar = () => {
        const tablebody = document.querySelector('tbody');
        if(info.length*35 <= tablebody.clientHeight) tablebody.style.width = '100%'
        else tablebody.style.width = 'calc(100% + 10px)'
    }

    useEffect(() => {
        hasScrollbar();
        window.addEventListener('resize', hasScrollbar);
        return () => {
            window.removeEventListener('resize', hasScrollbar);
        }
    }, [info])
    
    return (   
        <tbody>
            {info.length === 0?
                <tr>
                    <td colSpan='12'>고객사 정보가 존재하지 않습니다.</td>
                </tr>
            :
                info.map((corp, index) => {
                    return(
                        <OneCorp
                            key={corp.corp_idx}
                            corp_idx={corp.corp_idx}
                            total_count={search.total_count}
                            array_count={index}
                            page={page}
                            limit={limit}
                            item={corp}
                            count={info.length}
                            handleEdit={handleEdit}
                        />
                    );
                })
            }
        </tbody>
    );
};

export default CorpList;
