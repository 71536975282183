import { Button, InputGroup, Input, FormGroup, Col } from 'reactstrap';
import "../../css/search.css"
import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function SearchCorp(props) {

    let location = useLocation();

    const handleSearch = (e)  => {
        props.searchList(1);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            handleSearch(e); 
        } 
      }
    
    return (
        <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{marginBottom: '20px'}}><h3>고객사목록</h3></Col>
            <Col sm={4}>
                <InputGroup>
                    <div style={{position: 'relative', flex: '1 1 auto'}}>
                        <Input type="text" style={{width: '97%'}} className="search_box form-control" id="corp_name" value={props.search.corp_name} placeholder="고객사명을 입력하세요." onChange={(e) => props.setSearchData("corp_name", e.target.value)} onKeyPress={(e) => handleKeyPress(e)} />
                        {
                            props.search.corp_name.length ?
                                <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("corp_name", '')} />
                            : null
                        }
                    </div>
                    <div>
                        <Input type="select" className="search_box" id="discd" value={props.search.discd} onChange={(e) => props.setSearchData("discd", e.target.value)} onKeyPress={(e) => handleKeyPress(e)} >
                            <option value="" >모든 고객사</option>
                            <option value="0" >등록 고객사</option>
                            <option value="1" >해지 고객사</option>
                        </Input> 
                    </div>
                    <Button onClick={(e) => handleSearch(e)} >
                    검색
                    </Button>
                </InputGroup>                    
                {/* <input  />           
                <Button color="secondary" className="btn" size="sm" onClick={(e) => handleSearch(e)} >검색</Button> */}
            </Col>
         </FormGroup>

    );
}

export default SearchCorp;