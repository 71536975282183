import {useState, useEffect} from "react";
import { Form, FormGroup, Label, Input, Col ,Button } from 'reactstrap';
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";

const blank_data={
    record_idx: 0,
    corp_name: "",
    line_numer: "",
    local_number: "",
    peer_number: "",
    server_path: "",
    file_path: "",
    file_name: "",
    line_date: "",
    line_time: "",
    call_time: 0,
    call_type: "",
    mp3_size: 0,
    reg_date: "",
    checked: false
}

function RecordDetail(props){
    const [data, setData] = useState(blank_data);
    const history = useHistory();

    useEffect(() => {
        const historyState = history.block((location, action) => {
            if (action === 'POP'){
                return goList();
            }
            return true;
        })
        return () => historyState();
    }, [])

    useEffect(() => {
        let uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/record/'+props.match.params.record_idx;

        axios.get(uri
            ,{ 
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => { 
            if(res.data.res_code===200){
                setData(res.data.data);
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });

    },[]);

    function valueChange(e){
        setData( 
            {
                ...data,
                [e.target.name]:e.target.value
            }
        );
    }

    function goList(){
        // history.goBack();
        history.push({pathname: '/records', state: {...props.location.state}})
    }


    return(
        <div style={{paddingTop: '15px' ,paddingBottom: '15px'}}>
        <Form>
            <FormGroup row>
            <Label for="lineIdx" sm={2}>색인</Label>
            <Col sm={10}>
            <Input type="text"  name="record_idx" id="line_idx" value={data.record_idx|| ''} readOnly="readonly" placeholder="색인번호입니다" onChange={(e) => valueChange(e)}/>
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="corpName" sm={2}>고객사명</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="corp_name" id="corp_name" readOnly="readonly" value={data.corp_name|| ''} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="line_number" sm={2}>전화번호</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="line_number" id="line_number" readOnly="readonly" value={data.line_number|| ''} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="local_number" sm={2}>내선번호</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="local_number" id="local_number" readOnly="readonly" value={data.local_number|| ''} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>


            <FormGroup row>
            <Label for="peer_number" sm={2}>상대방번호</Label>
            <Col sm={10}>
            <Input type="text" name="peer_number" id="peer_number"  value={data.peer_number|| ''} readOnly="readonly" placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="server_path" sm={2}>서버경로</Label>
            <Col sm={10}>
            <Input type="text" name="server_path" id="server_path" value={data.server_path|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="file_path" sm={2}>파일경로</Label>
            <Col sm={10}>
            <Input type="text" name="file_path" id="file_path" value={data.file_path|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="file_name" sm={2}>파일명</Label>
            <Col sm={10}>
            <Input type="text" name="file_name" id="file_name" value={data.file_name|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="line_date" sm={2}>통화일시</Label>
            <Col sm={10}>
            <Input type="text" name="line_date" id="line_date" value={data.line_date+" "+data.line_time|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="call_time" sm={2}>통화시간</Label>
            <Col sm={10}>
            <Input type="text" name="call_time" id="call_time" value={data.call_time|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="line_date" sm={2}>착발신일시</Label>
            <Col sm={10}>
            <Input type="text" name="in_date" id="in_date" value={data.in_date+" "+data.in_time|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="duration" sm={2}>Duration</Label>
            <Col sm={10}>
            <Input type="text" name="duration" id="duration" value={data.duration|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="call_type" sm={2}>착발신여부</Label>
            <Col sm={10}>
            <Input type="text" name="call_type" id="call_type" value={data.call_type|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="call_type" sm={2}>통화상태</Label>
            <Col sm={10}>
            <Input type="text" name="call_status" id="call_status" value={data.call_status|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="call_type" sm={2}>그룹명</Label>
            <Col sm={10}>
            <Input type="text" name="group_name" id="group_name" value={data.group_name|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="call_type" sm={2}>유형</Label>
            <Col sm={10}>
            <Input type="text" name="kind" id="kind" value={data.kind|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>


            <FormGroup row>
            <Label for="reg_date" sm={2}>등록일</Label>
            <Col sm={10}>
            <Input type="text" name="reg_date" id="reg_date" value={data.reg_date|| ''} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>



            <p>
            </p>
            
            <FormGroup check row style={{textAlign: "right"}}>
                <Col sm={{ size: 10, offset: 2 }}>
                
                <Button onClick={() => goList()}>목록으로</Button>
                </Col>
            </FormGroup>
        </Form>
        </div>
    );
}

export default RecordDetail;