import { Button, InputGroup, Input, FormGroup, Col, ButtonGroup } from 'reactstrap';

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../../css/search.css"

import {useLocation} from 'react-router-dom'

function SearchStatCorp(props) {
    
    let location = useLocation();

    const handleSearch = (e)  => {
        props.searchList(1);
    }

    const dateFunc = (mode) => {
        props.dateFunc(mode, props.search.req_month);        
    }

    return (
        <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{marginBottom: '20px'}}><h3>고객사별통계</h3></Col>
            <Col sm={5} className="">
                <InputGroup>
                    <div className="date_picker_div">
                        <DatePicker className="date_picker form-control" dateFormat="yyyy-MM" id='req_month' selected={props.search.req_month} onChange={(date) => props.setSearchData("req_month", date)} readOnly={true} />
                        <ButtonGroup className="ms-1" style={{ whiteSpace:'nowrap' }}>
                            <Button type="button" onClick={(e) => dateFunc('prevMonth')}>«</Button>
                            <Button type="button" onClick={(e) => dateFunc('nowMonth')}>당월</Button>
                            <Button type="button" onClick={(e) => dateFunc('nextMonth')}>»</Button>
                        </ButtonGroup>
                    </div>
                    <Button className="btn" onClick={(e) => handleSearch(e)} >
                    검색
                    </Button>
                </InputGroup>                    
                {/* <input  />           
                <Button color="secondary" className="btn" size="sm" onClick={(e) => handleSearch(e)} >검색</Button> */}
            </Col>
        </FormGroup>
        
        // <div style={{float: "right" ,padding: "10px"}}>
        //     <div className="date_picker_div">
        //     <DatePicker className="date_picker" dateFormat="yyyy-MM" id='req_month' selected={props.search.req_month} onChange={(date) => props.setSearchData("req_month",date)} />
        //     </div>
        //    <Button color="secondary" size="sm" onClick={(e) => handleSearch(e)} > &nbsp;&nbsp;검색&nbsp;&nbsp;  </Button>
        // </div>

    );
}

export default SearchStatCorp;