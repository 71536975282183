import React ,{useState, useEffect} from "react";
import axios from 'axios'
import { Table,Button } from 'reactstrap';
import '../../css/Tables.css';

import RecordList from './RecordList'
import SearchRecord from './SearchRecord'
import PageNav from '../PageNav'
import PageCount from "../PageCount";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { getCookie } from "../../ReactCookie";

import RePagination from "../RePagination";

const base_search={
    page: 1,
    limit: 20,
    active_page: 0,
    total_page_cnt: 0,
    total_count: 0,
    searchData: "",
    searchData2: "",
    key1: "",
    key2: "",    
    from: (new Date().setDate(new Date().getDate())),
    to: new Date(),
    kind_yn: "",
}


function RecordTable(props) {

    const [first, setFirst] = useState(false);
            
    const [search, setSearch] = useState(base_search);
    const [date, setDate] = useState([base_search.from, base_search.to]);

    const [records, setRecords] = useState([]);
    const history = useHistory();
    
    useEffect(() => {
        // searchList(1);
        if(history.location.state && history.location.state.nowPage !== 0 && history.location.state) searchList(history.location.state.searchOption.page, history.location.state.searchOption);
        else searchList(1);
        setFirst(true)
    }, []);

    useEffect(() => {
        if(first) searchList(1);
    }, [search.kind_yn])

    function searchList(num, options){        
       
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/records/list';

        axios.get(url_path
            ,{ 
                params: {
                    page: num,
                    limit: search.limit,
                    searchData: options === undefined ? search.searchData : options.searchData,
                    searchData2: options === undefined ? search.searchData2 : options.searchData2,
                    key1: options === undefined ? search.key1 : options.key1,
                    key2: options === undefined ? search.key2 : options.key2,
                    from: moment(options === undefined ? search.from : options.from).format('YYYY-MM-DD'),
                    to: moment(options === undefined ? search.to : options.to).format('YYYY-MM-DD'),
                    kind_yn: options === undefined ? search.kind_yn : options.kind_yn,
                },
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
            .then(res => { 
            if(res.data.res_code===200){
                setSearch( 
                    {
                        ...search,
                        page: res.data.page,
                        limit: res.data.limit,
                        total_count:res.data.total_count,
                        searchData: res.config.params.searchData,
                        searchData2: res.config.params.searchData2,
                        key1: res.config.params.key1,
                        key2: res.config.params.key2,
                        from:  new Date(res.config.params.from),
                        to: new Date(res.config.params.to),
                        kind_yn: res.config.params.kind_yn,
                    }
                );
                
                if(res.data.data===null){
                    setRecords([]);
                }else{
                    setRecords(res.data.data);
                }
                
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });
        
    }

    function handleEdit(ev,record_idx) {
        let uri  = '/record/'+record_idx;
        //ev.preventDefault();
        // props.history.push(uri);
        props.history.push({pathname: uri, state: {searchOption: search}});
    }

    function handleRemove(){
       
        const arr_rem = [];
        records.map(
            record =>{
                if(record.checked){
                    // console.log("check_box true:",record.record_idx,record.checked);
                    arr_rem.push(record.record_idx);
                    //setRemoveIdx([...removeIdx, corp.corp_idx]);
                }
                
            }
        );

        if(arr_rem.length===0){
            alert('체크박스를 선택해주세요');
            return;
        }

        if(!window.confirm('삭제하시겠습니까?')){
            return;
        }

        let json = {"record_idx":arr_rem};     
        
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/records/delete',json
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        ) 
        .then(response => { 
            if(response.data.res_code===200){
                searchList(search.page);

            }else{
                if(response.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(response.data.res_msg);
                }
                
            }
            
            
        });
    }

    function setSearchData(name, value) {

        setSearch( 
            {
                ...search,
                [name]:value
            }
        );
    }


    function printExcel(){
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/xls/record/list'
        url_path+="?corp_name="+search.corp_name;
        url_path+="&line_number="+search.line_number;
        url_path+="&local_number="+search.local_number;
        url_path+="&peer_number="+search.peer_number;
        if(search.from!==''){
            url_path+="&from="+moment(search.from).format('YYYY-MM-DD');
        }
        if(search.to!==''){
            url_path+="&to="+moment(search.to).format('YYYY-MM-DD');
        }
        window.open(url_path,'_blank');
    }


    function dateFunc(mode, from, to) {

        const now = new Date();

        let month_start_day = new Date(now.getFullYear(), now.getMonth(), 1);
	    let month_last_day = new Date(now.getFullYear(), now.getMonth()+1, 0);

        let date_from = new Date(from);
        let date_to = new Date(to);
        
        if (mode === "prevDay") {            
            date_from = new Date(from).setDate(date_from.getDate() - 1);
            date_to = new Date(from).setDate(new Date(from).getDate() - 1);
        } else if (mode === "nowDay") {
            date_from = new Date().setDate(new Date().getDate());
            date_to = new Date().setDate(new Date().getDate());
        } else if (mode === "nextDay") {
            date_from = new Date(from).setDate(new Date(from).getDate() + 1);
            date_to = new Date(from).setDate(new Date(from).getDate() + 1);
        }       
                
        
        else if (mode === "prevMonth") {
            date_from = new Date(new Date(from).getFullYear(), new Date(from).getMonth() - 1, 1);
            date_to = new Date(new Date(from).getFullYear(), new Date(from).getMonth(), 0);            

        } else if (mode === "nowMonth") {
            date_from = month_start_day;
            date_to = month_last_day;

        } else if (mode === "nextMonth") {
            date_from = new Date(new Date(from).getFullYear(), new Date(from).getMonth() + 1, 1);
            date_to = new Date(new Date(from).getFullYear(), new Date(from).getMonth() +2, 0);            
        }

         setSearch({
                ...search,
                from: date_from,
                to: date_to
        });
    }
    
    return(
        <div style={{position: 'relative', height: 'calc(100% - 60px)'}}>
            <SearchRecord search={search} setSearchData={setSearchData} searchList={searchList} dateFunc={dateFunc} />

            <PageCount search={search} setSearchData={setSearchData}/>
            
            <Table hover className="recordTable">
                <thead>
                    <tr style={{width: "100%"}}>
                        <th style={{width: "2%"}} ></th>
                        <th style={{width: "5%"}} >idx</th>
                        <th style={{width: "10%" }}>고객사명</th>
                        <th style={{width: "12%"}}>회선명</th>
                        <th style={{width: "12%"}}>통화일시</th>
                        <th style={{width: "15%"}}>전화번호</th>
                        <th style={{width: "5%"}}>내선</th>
                        <th style={{width: "15%"}}>상대방번호</th>
                        <th style={{width: "5%"}}>통화</th>
                        <th style={{width: "8%"}}>착발신</th> 
                        <th style={{width: "8%"}}>상태</th> 
                        <th style={{width: "5%"}}></th>  
                    </tr>
                </thead>       
                <RecordList info={records} handleEdit={handleEdit}/>
            </Table>
            <div className="paginationPosition">
                { search.total_count === 0 ?
                    null
                :
                    <div style={{ float: "left" }}>
                        {/*****      PageNav 없고 RePagination만 있으면 페이지 표시 안 됨.      *****/}
                        <PageNav  search={search} searchList={searchList} setSearchData={setSearchData} />
                        <RePagination search={search} searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
                    </div>
                }

                <div style={{float: "right"}}>
                <Button color="secondary" size="sm" onClick={() => printExcel()} >엑셀출력</Button>
                &nbsp;&nbsp;
                <Button color="secondary" size="sm" onClick={(e) => handleRemove()}> &nbsp;&nbsp;삭제&nbsp;&nbsp;  </Button>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default RecordTable;