
import { Button, ButtonGroup, InputGroup, Input, FormGroup, Col } from 'reactstrap';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../../css/search.css"
import {useLocation} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function SearchErrLog(props) {
    
    let location = useLocation();

    const handleSearch = (e)  => {
        props.searchList(1);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            handleSearch(e); 
        } 
    }

     const dateFunc = (mode) => {
         props.dateFunc(mode, props.search.from, props.search.to);        
    }

    return (

        <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{marginBottom: '20px'}}><h3>에러로그목록</h3></Col>
            <Col sm={9} style={{ display:'flex' }}>
                <div className="date_picker_div" style={{ display: 'flex' , flexFlow:'row nowrap' , justifyContent:'flexEnd' }}>
                    <DatePicker className="date_picker form-control" dateFormat="yyyy-MM-dd" id='from' selected={props.search.from} onChange={(date) => props.setSearchData("from", date)} /> ~
                    <DatePicker className="date_picker form-control" dateFormat="yyyy-MM-dd" id='to' selected={props.search.to} onChange={(date) => props.setSearchData("to", date)} />
                    
                    <ButtonGroup className="ms-1" style={{ whiteSpace:'nowrap' }}>
                        <Button type="button" onClick={(e) => dateFunc('prevDay')}>«</Button>
                        <Button type="button" onClick={(e) => dateFunc('nowDay')}>당일</Button>
                        <Button type="button" onClick={(e) => dateFunc('nextDay')}>»</Button>
                    </ButtonGroup>

                    <ButtonGroup className="ms-1" style={{ whiteSpace:'nowrap' }}>
                        <Button type="button" onClick={(e) => dateFunc('prevMonth')}>«</Button>
                        <Button type="button" onClick={(e) => dateFunc('nowMonth')}>당월</Button>
                        <Button type="button" onClick={(e) => dateFunc('nextMonth')}>»</Button>
                    </ButtonGroup>

                </div>
                <InputGroup>
                    <div style={{position: 'relative', display: 'flex', flex: 1}}>
                        <Input type="text" className="search_box" id="login_id"  value={props.search.login_id} placeholder="아이디" onChange={(e) => props.setSearchData("login_id",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)} style={{width: '100%'}} />
                        {
                            props.search.login_id.length ?
                                <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("login_id", '')} />
                            : null
                        }
                    </div>
                    <div style={{position: 'relative', display: 'flex', flex: 1}}>
                        <Input type="text" className="search_box" id="line_name"  value={props.search.line_name} placeholder="회선명" onChange={(e) => props.setSearchData("line_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)} style={{width: '100%'}} />
                        {
                            props.search.line_name.length ?
                                <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("line_name", '')} />
                            : null
                        }
                    </div>
                    <div style={{position: 'relative', display: 'flex', flex: 1}}>
                        <Input type="text" className="search_box" id="action"  value={props.search.action} placeholder="액션" onChange={(e) => props.setSearchData("action",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)} style={{width: '100%'}} />
                        {
                            props.search.action.length ?
                                <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("action", '')} />
                            : null
                        }
                    </div>
                    <Button className="btn" onClick={(e) => handleSearch(e)} >
                    검색
                    </Button>
                </InputGroup>                    
                {/* <input  />           
                <Button color="secondary" className="btn" size="sm" onClick={(e) => handleSearch(e)} >검색</Button> */}
            </Col>
        </FormGroup>

        

    //     <div style={{float: "right" ,padding: "10px"}}>
    //         <div className="date_picker_div">
    //         <DatePicker className="date_picker" dateFormat="yyyy-MM-dd" id='from' selected={props.search.from} onChange={(date) => props.setSearchData("from",date)} />
    //         </div>
    //         <div style={{float: "left"}}> ~ </div>
    //          <div className="date_picker_div">
    //          <DatePicker className="date_picker" dateFormat="yyyy-MM-dd" id='to' selected={props.search.to} onChange={(date) => props.setSearchData("to",date)} />
    //          </div>


    //       <input type="text" className="search_box" id="login_id"  value={props.search.login_id} placeholder="사용자명" onChange={(e) => props.setSearchData("login_id",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
    //       <input type="text" className="search_box" id="login_name"  value={props.search.login_name} placeholder="회사명/관리자" onChange={(e) => props.setSearchData("login_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
	//       <input type="text" className="search_box" id="action"  value={props.search.action} placeholder="유형" onChange={(e) => props.setSearchData("action",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>

          
    //        <Button color="secondary" size="sm" onClick={(e) => handleSearch(e)} > &nbsp;&nbsp;검색&nbsp;&nbsp;  </Button>
    //     </div>

    );
}

export default SearchErrLog;
