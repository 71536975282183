
function OneStatCorp({item }){
  
    return (
        <tr style={{verticalAlign: 'middle'}}>
            <td style={{width: "15%"}}>{item.stat_name}</td>
            <td style={{width: "15%"}}>{item.line_count}</td>
            <td style={{width: "15%"}}>{item.record_count}</td>
            <td style={{width: "15%"}}>{item.sms_count}</td>
        </tr>
    );

}

export default OneStatCorp;