import React ,{useState, useEffect} from "react";
import axios from 'axios'

import { Table,Button } from 'reactstrap';
import '../../css/Tables.css';

import AdminList from './AdminList'
import SearchAdmin from './SearchAdmin'
import PageNav from '../PageNav'
import PageCount from "../PageCount";

import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";

import RePagination from "../RePagination";

const base_search={
    page: 1,
    limit: 20,
    total_count: 0,
    admin_id: "",
    admin_name: "",
}

function AdminTable(props){
    const [search, setSearch] = useState(base_search);
    const [adms, setAdms] = useState([]);
    const history = useHistory();

    useEffect( () => {
        // searchList(1);
        if(history.location.state && history.location.state.nowPage !== 0 && history.location.state) searchList(history.location.state.searchOption.page, history.location.state.searchOption);
        else searchList(1);
    },[]);

    function searchList(num, options){      
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/adms/list';

        axios.get(url_path
            ,{ 
                params: {
                    page: num,
                    limit: search.limit,
                    admin_id: options === undefined ? search.admin_id : options.admin_id,
                    admin_name: options === undefined ? search.admin_name : options.admin_name,
                },
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => { 
            if(res.data.res_code===200){
                setSearch( 
                    {
                        ...search,
                        page: res.data.page,
                        limit: res.data.limit,
                        total_count:res.data.total_count,
                        admin_id: res.config.params.admin_id,
                        admin_name: res.config.params.admin_name,
                    }
                );
                
                if(res.data.data===null){
                    setAdms([]);
                }else{
                    setAdms(res.data.data);
                }
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });
        
    }

    function handleEdit(ev,admin_idx) {
        let uri  = '/adm/'+admin_idx;
        //ev.preventDefault();
        // history.push(uri);
        props.history.push({pathname : uri, state: {searchOption: search}});
    }

    function handleRemove(){
       
      
        const arr_rem = [];
        adms.map(
            adm =>{
                if(adm.checked){
                    // console.log("check_box true:",adm.admin_idx,adm.checked);
                    arr_rem.push(adm.admin_idx);
                    //setRemoveIdx([...removeIdx, corp.corp_idx]);
                }
                
            }
        );

        if(arr_rem.length===0){
            alert('체크박스를 선택해주세요');
            return;
        }

        if(!window.confirm('삭제하시겠습니까?')){
            return;
        }

        let json = {"admin_idx":arr_rem};        
        
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/adms/delete',json
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        ) 
        .then(response => { 
            if(response.data.res_code===200){                
                searchList(search.page);

            }else{
                if(response.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(response.data.res_msg);
                }
                
            }
            
            
        });
    }

    function setSearchData(name,value){
        setSearch( 
            {
                ...search,
                [name]:value
            }
        );
    }



    return(
        <div style={{position: 'relative', height: 'calc(100% - 60px)'}}>
            <SearchAdmin search={search} setSearchData={setSearchData} searchList={searchList} key_name='line' />
            <PageCount search={search} setSearchData={setSearchData}/>
            <Table hover className="admTable">
                <thead>
                    <tr style={{width: "100%"}}>
                        <th style={{width: "2%"}} ></th>
                        <th style={{width: "5%"}} >idx</th>
                        <th style={{width: "10%"}}>아이디</th>
                        <th style={{width: "10%"}}>관리자명</th>
                        <th style={{width: "10%"}}>등록일</th>
                        <th style={{width: "10%"}}>최종로그인</th>
                        <th style={{width: "5%"}}></th>  
                    </tr>
                </thead>
       
                <AdminList info={adms} handleEdit={handleEdit}/>
        

            </Table>
            <div className="paginationPosition">
                {adms.length === 0 ?
                    null
                :
                    <div style={{float: "left"}}>
                        <PageNav search={search} searchList={searchList} setSearchData={setSearchData}/>
                        <RePagination searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
                    </div>
                }

                <div style={{float: "right"}}>
                <Button color="secondary" size="sm" onClick={(e) => handleEdit(e,0)} >관리자 신규등록</Button>
                &nbsp;&nbsp;
                <Button color="secondary" size="sm" onClick={(e) => handleRemove()}> &nbsp;&nbsp;삭제&nbsp;&nbsp;  </Button>
                </div>
            </div>
        </div>
    );
}

export default AdminTable;