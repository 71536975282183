
import { useEffect } from 'react';
import OneLine from './OneLine'


function LineList({ info, nowPage, searchData, handleEdit, handleList, checkedline, setCheckedline }) {

    const hasScrollbar = () => {
        const tablebody = document.querySelector('tbody');
        if(info.length*35 <= tablebody.clientHeight) tablebody.style.width = '100%'
        else tablebody.style.width = 'calc(100% + 10px)'
    }

    useEffect(() => {
        hasScrollbar();
        window.addEventListener('resize', hasScrollbar);
        return () => {
            window.removeEventListener('resize', hasScrollbar);
        }
    }, [info])

    return (
        <tbody>
            {info.length === 0 ?
                <tr>
                    <td colSpan='14'>회선 정보가 존재하지 않습니다.</td>
                </tr>
            :
                info.map( (line, index) =>{
                    return(
                        <OneLine key={line.line_idx} item={line} nowPage={nowPage} searchData={searchData} count={info.length - index}  handleEdit={handleEdit} handleList={handleList} checkedline={checkedline} setCheckedline={setCheckedline} />
                    );
                })
            }
        </tbody>
    );

}

export default LineList;