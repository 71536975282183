import React ,{useState, useEffect} from "react";
import axios from 'axios'
import { Table } from 'reactstrap';

import StatCorpList from './StatCorpList'
import SearchStatCorp from './SearchStatCorp'
import PageNav from '../PageNav'
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { getCookie } from "../../ReactCookie";

import RePagination from "../RePagination";
import '../../css/Tables.css';
import PageCount from "../PageCount";

const base_search={
    page: 1,
    limit: 20,
    total_count: 0,
    req_month: new Date()
}

function StatCorpTable(props){
    const [search, setSearch] = useState(base_search);
    const [stats, setStats] = useState([]);
    const history = useHistory();

    useEffect(() => {
        searchList(1);
        return () => setSearch(base_search)     // component unmount시 실행되는 함수 (state 변경 에러 생성 방지)
    },[]);

    function searchList(num){
       
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/stat/corps?page='+num;
        url_path+="&limit="+search.limit;
       if(search.req_month!==''){
            url_path+="&req_month="+moment(search.req_month).format('YYYY-MM');
        }

        axios.get(url_path
            ,{ 
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => { 
            if(res.data.res_code===200){
                setSearch({
                    ...search,
                    page: res.data.page,
                    limit: res.data.limit,
                    total_count: res.data.total_count,
                })

                setStats(res.data.data);
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });
    }


    function dateFunc(mode, req_month) {
        const now = new Date();

        let month_start_day = new Date(now.getFullYear(), now.getMonth(), 1);

        let date_from = new Date(req_month);
        
        if (mode === "prevMonth") {
            date_from = new Date(new Date(req_month).getFullYear(), new Date(req_month).getMonth() - 1, 1);

        } else if (mode === "nowMonth") {
            date_from = month_start_day;

        } else if (mode === "nextMonth") {
            date_from = new Date(new Date(req_month).getFullYear(), new Date(req_month).getMonth() + 1, 1);
        }
         setSearch({
                ...search,
                req_month: date_from
        });
    }

    function setSearchData(name,value){
        setSearch( 
            {
                ...search,
                [name]:value
            }
        );
    }

    return(
        <div style={{position: 'relative', height: 'calc(100% - 60px)'}}>
            <SearchStatCorp dateFunc={dateFunc} search={search} setSearchData={setSearchData} searchList={searchList}/>
            <PageCount search={search} setSearchData={setSearchData}/>
            <Table hover className="statcorpTable">
                <thead>
                <tr style={{width: "100%"}}>
                    <th style={{width: "15%"}}>고객사명</th>
                    <th style={{width: "15%"}}>등록회선수</th>
                    <th style={{width: "15%"}}>녹음파일수</th>
                    <th style={{width: "15%"}}>SMS</th>
                </tr>
                </thead>
       
                <StatCorpList info={stats} />
        

            </Table>

            <div className="paginationPosition">
                <div style={{float: "left"}}>
                    <PageNav search={search} searchList={searchList} setSearchData={setSearchData}/>
                    <RePagination searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
                </div>
            </div>
        </div>
    )
}


export default StatCorpTable;

