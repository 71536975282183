
import { useEffect } from 'react';
import OneAdmin from './OneAdmin'


function AdminList({info  ,handleEdit}){

    const hasScrollbar = () => {
        const tablebody = document.querySelector('tbody');
        if(info.length*35 <= tablebody.clientHeight) tablebody.style.width = '100%'
        else tablebody.style.width = 'calc(100% + 10px)'
    }

    useEffect(() => {
        hasScrollbar();
        window.addEventListener('resize', hasScrollbar);
        return () => {
            window.removeEventListener('resize', hasScrollbar);
        }
    }, [info])
    
    return (
        <tbody>
            {info.length === 0?
                <tr>
                    <td colSpan='7'>관리자 정보가 존재하지 않습니다.</td>
                </tr>
            :
                info.map( admin =>{
                    return(
                       <OneAdmin key={admin.admin_idx} item={admin}  handleEdit={handleEdit} />
                    );
                })
            }
        </tbody>
    );

}

export default AdminList;