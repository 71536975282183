import {useState, useEffect} from "react";
import { Form, FormGroup, Label, Input, Col ,Button } from 'reactstrap';
import axios from 'axios'

import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";

const blank_data={
    log_idx: 0,
    login_id: "",
    login_name: "",
    acc_auth: "",
    exe_auth: 0,
    action: "",
    action_desc: "",
    agent_type: "",
    reg_date: "",
    checked: false

}

function LogDetail(props){
    const [ data, setData ] = useState(blank_data);
    const history = useHistory();

    useEffect(() => {
        const historyState = history.block((location, action) => {
            if (action === 'POP'){
                return goList();
            }
            return true;
        })
        return () => historyState();
    }, [])

    useEffect( () => {
        let uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/log/'+props.match.params.log_idx;

        axios.get(uri
            ,{ 
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }    
        ) 
        .then(res => { 
            if(res.data.res_code===200){
                setData(res.data.data);
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });

    },[]);

    function valueChange(e){
        setData( 
            {
                ...data,
                [e.target.name]:e.target.value
            }
        );
    }

    function goList(){
        // history.goBack();
        history.push({
            pathname: '/logs',
            state: {...props.location.state}
        })
    }


    return(
        <div style={{paddingTop: '15px' ,paddingBottom: '15px'}}>
        <Form>
            <FormGroup row>
            <Label for="log_idx" sm={2}>색인</Label>
            <Col sm={10}>
            <Input type="text"  name="log_idx" id="agent_idx" value={data.log_idx} readOnly="readonly" placeholder="색인번호입니다" onChange={(e) => valueChange(e)}/>
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="login_id" sm={2}>아이디</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="login_id" id="login_id" readOnly="readonly"  value={data.login_id} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="line_number" sm={2}>관리자/회사명</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:true}} name="login_name" id="login_name" value={data.login_name} readOnly="readonly" placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>
	    
            <FormGroup row>
            <Label for="line_number" sm={2}>유형</Label>
            <Col sm={10}>
            <Input type="text"  name="action" id="action" value={data.action} readOnly="readonly"  placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

	     <FormGroup row>
            <Label for="line_number" sm={2}>유형상세</Label>
            <Col sm={10}>
            <Input type="text"  name="action_desc" id="action_desc" value={data.action_desc} readOnly="readonly"  placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

	     <FormGroup row>
            <Label for="agent_type" sm={2}>타입</Label>
            <Col sm={10}>
            <Input type="text"  name="agent_type" id="agent_type" value={data.agent_type} readOnly="readonly"  placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>


		<FormGroup row>
            <Label for="acc_auth" sm={2}>접근권한</Label>
            <Col sm={10}>
            <Input type="text"  name="acc_auth" id="acc_auth" value={data.acc_auth} readOnly="readonly"  placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>


	   <FormGroup row>
            <Label for="acc_auth" sm={2}>실행권한</Label>
            <Col sm={10}>
            <Input type="text"  name="acc_auth" id="acc_auth" value={data.acc_auth} readOnly="readonly"  placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>


            <FormGroup row>
            <Label for="reg_date" sm={2}>등록일</Label>
            <Col sm={10}>
            <Input type="text" name="reg_date" id="reg_date" value={data.reg_date} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup check row style={{textAlign: "right"}}>
                <Col sm={{ size: 10, offset: 2 }}>
                
                <Button onClick={() => goList()}>목록으로</Button>
                </Col>
            </FormGroup>
        </Form>
        </div>
    );
}

export default LogDetail;