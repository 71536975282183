import React from 'react';
import ReactDOM from 'react-dom';
import Popup from 'react-popup';

import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'

ReactDOM.render(
  //<React.StrictMode>
  //<Router>
  //  <App Popup={Popup}/>      
  //</Router>  
  //<Router basename="/admin" Popup={Popup}>  
  <Router basename={process.env.REACT_APP_PUBLIC_URL} Popup={Popup}>
    <App />
  </Router>,
  //</React.StrictMode>,
  document.getElementById('root')
);
