import { Input } from 'reactstrap';
import {showPhone,showDate} from '../../util/StrUtil';

function OneApi({item  ,handleEdit}){
    item.checked=false;
    function onEdit(e){
        handleEdit(e,item.api_idx);
    };

    function handleChecked(event){
        item.checked=!item.checked;
    }

    
    return (
        <tr >
            <th scope="row" style={{width: "2%"}}><Input type="checkbox" id="chk"  onClick={(e) => handleChecked(e)} /></th>
            <th style={{width: "5%"}}>{item.api_idx}</th>
            <td style={{width: "10%"}} onClick={(e) => onEdit(e)}>{item.corp_name}</td>
            <td style={{width: "15%"}} onClick={(e) => onEdit(e)}>{showPhone(item.line_number)}</td>
            <td style={{width: "5%"}} onClick={(e) => onEdit(e)}>{item.local_number}</td>
            <td style={{width: "15%"}} onClick={(e) => onEdit(e)}>{showPhone(item.peer_number)}</td>
            {/* <td style={{textAlign: "left", width: "12%"}}>{showDate(item.api_time)}</td> */}
            <td style={{width: "12%"}}>{item.api_time}</td>
            <td style={{width: "5%"}} >{item.api_from}</td>
            <td style={{width: "5%"}} >{item.api_type}</td>
            
        </tr>
    );

}

export default OneApi;