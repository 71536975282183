import React ,{useState, useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


import axios from 'axios'
import CorpList from "./CorpList";
import { Table,Button } from 'reactstrap';

import '../../css/table.css';

import SearchCorp from "./SearchCorp";
import PageNav from "../PageNav";
import PageCount from "../PageCount";
import { useHistory, useLocation } from "react-router-dom";
import { getCookie } from "../../ReactCookie";

import RePagination from "../RePagination";

const base_search={
    page: 1,
    limit: 20,
    total_page_cnt:0,
    total_count: 0,
    corp_name: "",
    discd: ""
}

const CorpTable = (props) => {

    const history = useHistory();

    const [search, setSearch] = useState({
        page: history.location.state.searchOption ? history.location.state.searchOption.page : 1,
        limit: 20,
        total_page_cnt:0,
        total_count: 0,
        corp_name: history.location.state.searchOption ? history.location.state.searchOption.corp_name : '',
        discd: history.location.state.discd ? history.location.state.searchOption.discd : '',
    });
    const [corps, setCorps] = useState([]);

    const [page, setPage] = useState(0);

    //const [params, setParams] = useState([]);

    function searchList(num, corp_name, discd) {

        setPage(num);

        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/corps/list';
        // url_path+="&limit="+search.limit;
        // url_path+="&corp_name="+(search.corp_name);

        axios.get(url_path
            ,{ 
                params: {
                    page: num, 
                    limit: search.limit, 
                    corp_name: corp_name === undefined ? search.corp_name : corp_name,
                    discd: discd === undefined ? search.discd : discd
                },
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => {
            if(res.data.res_code===200){
                setSearch( 
                    {
                        ...search,
                        page: res.data.page,
                        limit: res.data.limit,
                        total_count: res.data.total_count,
                        corp_name: res.config.params.corp_name,
                        discd: res.config.params.discd,
                    }
                );
                
                if(res.data.data===null){
                    setCorps([]);
                }else{
                    setCorps(res.data.data);
                }
                
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });
        
    }

    useEffect(() => {
        // searchList(1);
        // searchList(history.location.state.searchOption ? history.location.state.searchOption.page : 1);

        if(history.location.state && !history.location.state.nowPage && history.location.state.searchOption) searchList(history.location.state.searchOption.page, history.location.state.searchOption.corp_name, history.location.state.searchOption.discd);
        else searchList(1, search.corp_name, search.discd);
    }, []);

    function setSearchData(name,value){
        setSearch( 
            {...search, [name]:value}
        );
    }
    
    function handleEdit(event,corp_idx) {
        let uri  = '/corp/'+corp_idx;
        props.history.push({pathname : uri, state: {searchOption: search, prevPath: history.location.pathname}});
    }

    const handleRemove = (event)  => {
        const arr_rem = [];
        corps.map(
            corp =>{
                if(corp.checked){
                    arr_rem.push(corp.corp_idx);
                    //setRemoveIdx([...removeIdx, corp.corp_idx]);
                }
                
            }
        );

        if(arr_rem.length===0){
            alert('체크박스를 선택해주세요');
            return;
        }

        if(!window.confirm('선택한 고객사를 등록 해지하시겠습니까?')){
            return;
        }

        let json = {"corp_idx":arr_rem};

    
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/corps/delete',json
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        
        ) 
        .then(response => { 
            if(response.data.res_code===200){                
                searchList(search.page);

            }else{
                if(response.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(response.data.res_msg);
                }
            }
        });
    }

    function printExcel(){

        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/xls/corp/list'
        url_path+="?corp_name="+search.corp_name;
        window.open(url_path,'_blank');
    }

    return(
        <>
            <div style={{position: 'relative', height: 'calc(100% - 60px)'}}>                                
                <SearchCorp search={search} setSearchData={setSearchData} searchList={searchList} />                

                <PageCount search={search} setSearchData={setSearchData} />

                {/* <p className="text-end">
                    총 {search["total_count"]} 건 / 페이지 : ({search["page"]} / {search["total_page_cnt"]})
                </p> */}

                <Table hover className="corpTable">                    
                    <thead>                        
                        <tr style={{ width: "100%" }}>                            
                            <th style={{width: "2%"}} ></th>
                            <th style={{width: "5%"}} >idx</th>
                            <th style={{ textAlign: 'center' }}>고객사명</th>
                            <th style={{ textAlign: 'center' }}>대표번호</th>
                            {/* <th style={{width: "10%"}}>회선수</th> */}
                            <th style={{width: "8%"}}>회선수</th>
                            {/* <th style={{width: "10%"}}>주기(분)</th> */}
                            <th style={{width: "8%"}}>주기(분)</th>
                            {/* <th style={{width: "10%"}}>저장(일)</th> */}
                            <th style={{width: "8%"}}>저장(일)</th>
                            <th style={{width: "10%"}}>영업시간</th>
                            <th style={{width: "10%"}}>Agent</th>
                            <th style={{width: "10%"}}>등록일</th>
                            <th style={{width: "5%"}}>사용여부</th>  
                            <th style={{width: "5%"}}>등록</th>  
                            <th style={{ width: "5%" }}>설정</th>                              
                        </tr>                        
                    </thead>                            
                    <CorpList info={corps} search={search} handleEdit={handleEdit} page={page} limit={search.limit} />
                </Table>
                <div className="paginationPosition">
                    {corps.length === 0 ?
                        null
                    :
                        <div style={{float: "left"}}>
                            <PageNav search={search} searchList={searchList} setSearchData={setSearchData}/>
                            <RePagination searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
                        </div>
                    }
                    <div style={{float: "right"}}>
                        <Button color="secondary" size="sm" onClick={() => printExcel()} >엑셀출력</Button>
                        &nbsp;&nbsp;
                        <Button color="secondary" size="sm" onClick={(e) => handleEdit(e,0)} >고객사 신규등록</Button>
                        &nbsp;&nbsp;
                        <Button color="secondary" size="sm" onClick={(e) => handleRemove(e)}> &nbsp;&nbsp;해지&nbsp;&nbsp;  </Button>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CorpTable;
