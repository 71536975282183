
function OneStatMonth({item }){
    const date = new Date().getDate();
    const month = new Date().getMonth()+1;
    const item_date = item.stat_name.split('-')[2];
    const item_month = item.stat_name.split('-')[1];

    return (
            < >
                {Number(item_date) > Number(date) && Number(item_month) === Number(month) ?
                    null
                :
                    <tr style={{verticalAlign: 'middle', width: '100%', display:'grid', gridTemplateColumns:'1fr 1fr 1fr 1fr', alignItems:'center', textAlign:'center'}}>
                        <td>{item.stat_name}</td>
                        <td>{item.line_count}</td>
                        <td>{item.record_count}</td>
                        <td>{item.sms_count}</td>
                    </tr>
                }
            </>
    );

}

export default OneStatMonth;