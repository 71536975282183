import React,{useState, useEffect} from 'react';
import { Button, InputGroup, Input , ListGroup ,ListGroupItem } from 'reactstrap';
import axios from 'axios'
import { getCookie } from "../ReactCookie";
import PageNav from './PageNav';
import RePagination from './RePagination';
import { replaceNumber } from '../util/StrUtil';

export const Bg = (props) => {
    return (
        <div className="bg"></div>
    )
}

export const AgentSearchPopup = (props) => {

    useEffect(() => {
        Search();
    },[]);

    const [data, setData] = useState();
    const [searchData, setSearchData] = useState({
        page: 1,
        limit: 10,
        keyword: '',
        total_count: 0,
        total_page_cnt: 0,
    });

    const changeSearchData = (name, value) => {
        setSearchData({
            ...searchData,
            [name]: value
        })
    }

    const Search = async (num) =>{

        await axios.get(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/agents/list/' , {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
                },
                params: {
                    searchData: searchData.keyword,             
                    page: num,       
                }
            }).then(res => {
            if(res.data.res_code===200){
                //setData(blank_data );                
                setData(res.data.data);     
                setSearchData({
                    ...searchData,
                    page: res.data.page,
                    total_count: res.data.total_count,
                    keyword: res.config.params.searchData,
                })           
            }else{
                //alert(res.data.res_msg);
            }
        });
    }

    function valueChange(e){
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value,
        })
    }

    function handleKeyPress(e) {               
        if (e.key === "Enter") {
            Search();
          //alert('Enter clicked!!!');    
        } 
    }

    function selectAgent(idx, name) {        
        props.selectAgentData(idx,name);
        //handleEdit(e,item.line_idx);
    };
    
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none" }}>            
            <div className="d-flex p-2" style={{ justifyContent:'space-between' }}>
                <div className="">
                    영업자 검색 팝업
                </div>
                <div className="popup_btn warning pointer"  onClick={ ()=>props.close(false) }>
                    <Button color="danger" className="btn-sm">X</Button>
                </div>
            </div>
            <div className="agent-search-popup ps-2 pe-2" >
                <InputGroup>
                    <Input className="m-0" name="keyword" onChange={(e) => valueChange(e)} onKeyPress={(e) => handleKeyPress(e)} placeholder="회사명, 영업자명 또는 전화번호 입력" />
                    <Button onClick={() => Search()}>
                    검색
                    </Button>
                </InputGroup>
                
                <hr/>

                <div className="d-flex" style={{ justifyContent:'space-around' , textAlign:'left' }}>
                    <div style={{ width:'30%'}} className="ps-3">회사명</div>
                    <div style={{ width:'40%'}} className="ps-3">영업자명</div>
                    <div style={{ width:'30%'}} className="ps-3">전화번호</div>
                </div>
                
                <ListGroup style={{overflowY: 'auto', height: '340px'}}>
                    { 
                     data && data.map( (agent, index) =>{
                        return(
                            <ListGroupItem action tag="button" key={agent.agent_idx} onClick={() => selectAgent(agent.agent_idx, agent.office_name)}  className="d-flex" style={{ justifyContent:'space-evenly' }}>
                                <div style={{ width:'30%' }}>{agent.office_name || ''}</div>
                                <div className='ps-2' style={{ width:'40%' }}>{agent.agent_name || ''}</div>
                                <div className='ps-3' style={{ width:'30%' }}>{agent.office_number || ''}</div>                                 
                            </ListGroupItem>
                        );
                    })}                    
                </ListGroup>

                <div className="paginationPosition">
                    {   data && data.length === 0 ?
                            null
                        :
                            <div>
                                <PageNav search={searchData} searchList={Search} setSearchData={changeSearchData}/>
                                <RePagination searchList={Search} setSearchData={changeSearchData} currentPage={searchData.page} count={searchData.total_count} pageCount={searchData.total_page_cnt} />
                            </div>
                    }
                </div>
            </div>
        
        </div>
    )
}



export const CorpSearchPopup = (props) => {

    useEffect(() => {
        Search();
    },[]);

    const [data, setData] = useState();
    // const [searchData, setSearchData] = useState('');
    const [searchData, setSearchData] = useState({
        page: 1,
        limit: 10,
        corp_name: '',
        total_count: 0,
        total_page_cnt: 0,
    });
    
    const changeSearchData = (name, value) => {
        setSearchData({
            ...searchData,
            [name]: value
        })
    }

    const Search = async (num) =>{

        await axios.get(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/corps/list' , {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.cookie
                },
                params: {
                    corp_name: searchData.corp_name,                    
                    page: num,                  
                }
            }).then(res => {
            if(res.data.res_code===200){
                //setData(blank_data );                
                setData(res.data.data);       
                // setPage(res.data.page)         
                setSearchData({
                    ...searchData,
                    page: res.data.page,
                    total_count: res.data.total_count,
                    corp_name: res.config.params.corp_name,
                })
            }else{
                //alert(res.data.res_msg);
            }
        });
    }

    function valueChange(e){
        setSearchData({
            ...searchData,
            [e.target.name]: e.target.value,
        })
    }

    function handleKeyPress(e) {               
        if (e.key === "Enter") {
            Search();
          //alert('Enter clicked!!!');    
        } 
    }

    function selectCorp(idx, name) {        
        props.selectCorpData(idx,name);
        //handleEdit(e,item.line_idx);
    };
    
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none" }}>            
            <div className="d-flex p-2" style={{ justifyContent:'space-between' }}>
                <div className="">
                    고객사 검색 팝업
                </div>
                <div className="popup_btn warning pointer"  onClick={ ()=>props.close(false) }>
                    <Button color="danger" className="btn-sm">X</Button>
                </div>
            </div>
            <div className="agent-search-popup ps-2 pe-2" >
                <InputGroup>
                    <Input className="m-0" name="corp_name" onChange={(e) => valueChange(e)} onKeyPress={(e) => handleKeyPress(e)} placeholder="고객사명 입력" />
                    <Button onClick={() => Search()}>
                    검색
                    </Button>
                </InputGroup>
                
                <hr/>

                <div className="d-flex" style={{ justifyContent:'space-around' , textAlign:'left' }}>
                    <div style={{ width:'20%'}} className="ps-3">고객사idx</div>
                    <div style={{ width:'50%'}} className="ps-3">고객사명</div>
                    <div style={{ width:'30%'}} className="ps-3">전화번호</div>
                </div>
                
                <ListGroup style={{overflowY: 'auto', height: '340px'}}>
                    { 
                     data && data.map( (corp, index) =>{
                        return(
                            <ListGroupItem action tag="button" key={corp.corp_idx} onClick={() => selectCorp(corp.corp_idx, corp.corp_name)}  className="d-flex" style={{ justifyContent:'space-evenly' }}>
                                <div style={{ width:'20%' }}>{corp.corp_idx || ''}</div>
                                <div className='ps-1' style={{ width:'50%' }}>{corp.corp_name || ''}</div>
                                <div className='ps-3' style={{ width:'30%' }}>{replaceNumber(corp.corp_number) || ''}</div>                                 
                            </ListGroupItem>
                        );
                    })}                    
                </ListGroup>

                <div className="paginationPosition">
                    {   data && data.length === 0 ?
                            null
                        :
                            <div>
                                <PageNav search={searchData} searchList={Search} setSearchData={changeSearchData}/>
                                <RePagination searchList={Search} setSearchData={changeSearchData} currentPage={searchData.page} count={searchData.total_count} pageCount={searchData.total_page_cnt} />
                            </div>
                    }
                </div>
            </div>
        
        </div>
    )
}


