import React ,{useState, useEffect} from "react";
import axios from 'axios'

import { Table,Button } from 'reactstrap';

import LogList from './LogList'
import SearchLog from './SearchLog'
import PageNav from '../PageNav'
import PageCount from "../PageCount";

import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";
import moment from 'moment';

import RePagination from "../RePagination";

const base_search={
    page: 1,
    limit: 20,
    total_count: 0,
    login_id: "",
    login_name: "",
    action: "",
    from: (new Date().setDate(new Date().getDate()-7)),
    to: new Date()
}

function LogTable(props){
    const [search, setSearch] = useState(base_search);
    const [logs, setLogs] = useState([]);
    const history = useHistory();

    useEffect( () => {
        // searchList(1);
        if(history.location.state && history.location.state.nowPage !== 0 && history.location.state) searchList(history.location.state.searchOption.page, history.location.state.searchOption);
        else searchList(1);
    },[]);

    function searchList(num, options){
       
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/logs/list';
    //    url_path+="&limit="+search.limit;
    //    url_path+="&login_id="+search.login_id;
    //    url_path+="&login_name="+search.login_name;
    //    url_path+="&action="+search.action;
    //    if(search.from!==''){
    //         url_path+="&from="+moment(search.from).format('YYYY-MM-DD');
    //     }
    //     if(search.to!==''){
    //         url_path+="&to="+moment(search.to).format('YYYY-MM-DD');
    //     }

            axios.get(url_path
            ,{ 
                params: {
                    page: num,
                    limit: search.limit,
                    login_id: options === undefined ? search.login_id : options.login_id,
                    login_name: options === undefined ? search.login_name : options.login_name,
                    action: options === undefined ? search.action : options.action,
                    from: moment(options === undefined ? search.from : options.from).format('YYYY-MM-DD'),
                    to: moment(options === undefined ? search.to : options.to).format('YYYY-MM-DD'),
                },
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => { 
            if(res.data.res_code===200){
                setSearch( 
                    {
                        ...search,
                        page: res.data.page,
                        limit: res.data.limit,
                        total_count:res.data.total_count,
                        login_id: res.config.params.login_id,
                        login_name: res.config.params.login_name,
                        action: res.config.params.action,
                        from:  new Date(res.config.params.from),
                        to: new Date(res.config.params.to),
                    }
                );

                if(res.data.data===null){
                    setLogs([]);
                }else{
                    setLogs(res.data.data);
                }

            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });
        
    }

    function handleEdit(ev,log_idx) {
        let uri  = '/log/'+log_idx;
        //ev.preventDefault();
        // history.push(uri);
        props.history.push({pathname: uri, state: {searchOption: search}});
    }

    function handleRemove(){
       
      
        const arr_rem = [];
        logs.map(
            log =>{
                if(log.checked){
                    arr_rem.push(log.log_idx);
                }
            }
        );

        if(arr_rem.length===0){
            alert('체크박스를 선택해주세요');
            return;
        }

        if(!window.confirm('삭제하시겠습니까?')){
            return;
        }

        let json = {"log_idx":arr_rem};        
        
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/logs/delete',json
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        ) 
        .then(response => { 
            if(response.data.res_code===200){
                searchList(search.page);

            }else{
                if(response.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(response.data.res_msg);
                }
                
            }
            
            
        });
    }

    function setSearchData(name,value){
        setSearch( 
            {
                ...search,
                [name]:value
            }
        );
    }

    function dateFunc(mode, from, to) {

        const now = new Date();

        let month_start_day = new Date(now.getFullYear(), now.getMonth(), 1);
	    let month_last_day = new Date(now.getFullYear(), now.getMonth()+1, 0);

        let date_from = new Date(from);
        let date_to = new Date(to);
        
        if (mode === "prevDay") {            
            date_from = new Date(from).setDate(date_from.getDate() - 1);
            date_to = new Date(from).setDate(new Date(from).getDate() - 1);
        } else if (mode === "nowDay") {
            date_from = new Date().setDate(new Date().getDate());
            date_to = new Date().setDate(new Date().getDate());
        } else if (mode === "nextDay") {
            date_from = new Date(from).setDate(new Date(from).getDate() + 1);
            date_to = new Date(from).setDate(new Date(from).getDate() + 1);
        }       
                
        
        else if (mode === "prevMonth") {
            date_from = new Date(new Date(from).getFullYear(), new Date(from).getMonth() - 1, 1);
            date_to = new Date(new Date(from).getFullYear(), new Date(from).getMonth(), 0);            

        } else if (mode === "nowMonth") {
            date_from = month_start_day;
            date_to = month_last_day;

        } else if (mode === "nextMonth") {
            date_from = new Date(new Date(from).getFullYear(), new Date(from).getMonth() + 1, 1);
            date_to = new Date(new Date(from).getFullYear(), new Date(from).getMonth() +2, 0);            
        }

        setSearch({
                ...search,
                from: date_from,
                to: date_to
        });
    }


    return(
        <div style={{position: 'relative', height: 'calc(100% - 60px)'}}>
            <SearchLog search={search} setSearchData={setSearchData} searchList={searchList} key_name='line' dateFunc={dateFunc} />
            <PageCount search={search} setSearchData={setSearchData}/>
            <Table hover className="logTable">
                <thead>
                    <tr style={{width: "100%"}}>
                        <th style={{width: "2%"}} ></th>
                        <th style={{width: "5%"}} >#</th>
                        <th style={{ textAlign: 'center' }}>아이디</th>
                        <th style={{width: "10%"}}>회사명</th>
                        <th style={{width: "10%"}}>액션</th>
                        <th style={{width: "20%"}}>액션상세</th>
                        <th style={{width: "10%"}}>유형</th>
                        <th style={{width: "5%"}}>접근권한</th>
                        <th style={{width: "10%"}}>실행권한</th>
                        <th style={{width: "12%"}}>등록일</th>  
                    </tr>
                </thead>       
                <LogList info={logs} handleEdit={handleEdit}/>
            </Table>
            <div className="paginationPosition">
                {logs.length === 0?
                    null
                :
                    <div style={{float: "left"}}>
                        <PageNav search={search} searchList={searchList} setSearchData={setSearchData}/>
                        <RePagination searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
                    </div>
                }
                <div style={{float: "right"}}>
                
                <Button color="secondary" size="sm" onClick={(e) => handleRemove()}> &nbsp;&nbsp;삭제&nbsp;&nbsp;  </Button>
                </div>
            </div>
        </div>
    );
}

export default LogTable;