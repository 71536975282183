import { Input } from 'reactstrap';


function OneLog({item  ,handleEdit}){
    item.checked=false;
    function onEdit(e){
        handleEdit(e,item.log_idx);
    };

    function handleChecked(event){
        item.checked=!item.checked;
    }

    
    return (
        <tr style={{verticalAlign: 'middle'}}>
            <th scope="row" style={{width: "2%"}}><Input type="checkbox" id="chk"  onClick={(e) => handleChecked(e)} /></th>
            <th style={{width: "5%"}}>{item.log_idx}</th>
            <td onClick={(e) => onEdit(e)}>{item.login_id}</td>
            <td style={{width: "10%"}} onClick={(e) => onEdit(e)}>{item.login_name}</td>
            <td style={{width: "10%"}}>{item.action}</td>
            <td style={{width: "20%"}}>{item.action_desc}</td>
            <td style={{width: "10%"}}>{item.agent_type}</td>
            <td style={{width: "5%"}}>{item.acc_auth}</td>
            <td style={{width: "10%"}}>{item.exe_auth}</td>
            <td style={{width: "12%"}}>{item.reg_date} {item.reg_time}</td>
        </tr>
    );

}

export default OneLog;