import { Input } from 'reactstrap';

import {showPhone,showDate} from '../../util/StrUtil';

function OneSms({item  ,handleEdit}){
    item.checked=false;
    function onEdit(e){
        handleEdit(e,item.sms_idx);
    };

    function handleChecked(event){
        item.checked=!item.checked;
    }

    
    return (
        <tr style={{verticalAlign: 'middle'}}>
            <th scope="row" style={{width: "2%"}}><Input type="checkbox" id="chk"  onClick={(e) => handleChecked(e)} /></th>
            <th style={{width: "5%"}}>{item.sms_idx}</th>
            <td style={{width: "10%"}} onClick={(e) => onEdit(e)}>{item.corp_name}</td>
            <td style={{width: "15%"}} onClick={(e) => onEdit(e)}>{showPhone(item.line_number)}</td>
            <td style={{width: "7%"}} onClick={(e) => onEdit(e)}>{item.local_number}</td>
            <td style={{width: "15%"}} onClick={(e) => onEdit(e)}>{showPhone(item.peer_number)}</td>
            {/* <td style={{textAlign: "left", width: "12%"}}>{showDate(item.sms_date+" "+item.sms_time)}</td> */}
            <td style={{width: "12%"}}>{item.sms_date+" "+item.sms_time}</td>
            <td>{item.sms_data}</td>
        </tr>
    );

}

export default OneSms;