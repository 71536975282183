/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useEffect } from 'react';
import _ from 'lodash';
// import styles from './RePagination.module.css';
import '../css/RePagination.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';

function RePagination({currentPage, pageCount, searchList, setSearchData }) {
  const blockpage = 10;
  const total_block = Math.ceil(pageCount/blockpage);
  const now_block = Math.ceil(currentPage/blockpage);

  let s_pageNum = (now_block-1)*blockpage+1;
  if(s_pageNum <= 0) s_pageNum=1;

  let e_pageNum = now_block*blockpage;
  if(e_pageNum>pageCount) e_pageNum=pageCount;

  const pages = _.range(s_pageNum, e_pageNum+1);

  function pageNavation(num){
    searchList(num);
  }

  return (
    <nav>
      <ul className="pagination">
        {currentPage === 1 ?
          <li><a className="page-link" style={{color: '#666', background:'#dee2e6'}}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </a></li>
        :
          <li style={{ cursor: "pointer" }}><a className="page-link" onClick={() => pageNavation(1)}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </a></li>
        }
        {now_block === 1 ?
          null
        :
          <li style={{ cursor: "pointer" }}><a className="page-link" onClick={() => pageNavation(s_pageNum-10)}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </a></li>
        }

        { 
          pages.map(page => 
            <li key={page}
              className={page === currentPage ? "page-item active" : "page-item"}
              style={{ cursor: "pointer" }}
            >
              <a className="page-link" onClick={() => pageNavation(page)}>{page}</a>
            </li>
          )
        }
        {now_block === total_block ? 
          null
        :
          <li style={{ cursor: "pointer" }}><a className="page-link" onClick={() => pageNavation(s_pageNum+10)}>
            <FontAwesomeIcon icon={faAngleRight} />
          </a></li>
        }
        {currentPage === pageCount ?
          <li><a className="page-link" style={{color: '#666', background:'#dee2e6'}}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </a></li>
        :
          <li style={{ cursor: "pointer" }}><a className="page-link" onClick={() => pageNavation(pageCount)}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          </a></li>
        }
        
      </ul>
    </nav>
  )
}

export default RePagination