import React ,{useState, useEffect} from "react";
import axios from 'axios'

import { Table,Button } from 'reactstrap';

import CallbackList from './CallbackList'
import SearchCallback from './SearchCallback'
import PageNav from '../PageNav'
import PageCount from "../PageCount";

import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";

import RePagination from "../RePagination";
import '../../css/Tables.css';

const base_search={
    page: 1,
    limit: 20,
    total_count: 0,
    corp_name: "",
    line_number: "",
    local_number: "",
    
}

function CallbackTable(props){
    const [search, setSearch] = useState(base_search);
    const [callBackData, setCallBackData] = useState([]);
    const history = useHistory();


    useEffect( () => {
        searchList(1);
    },[]);

    function searchList(num){

        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/callbacks/list?page='+num;
        url_path+="&limit="+search.limit;
        url_path+="&corp_name="+search.corp_name;
        url_path+="&line_number="+search.line_number;
        url_path+="&local_number="+search.local_number;

        axios.get(url_path
            ,{ 
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+getCookie("access_token")
                }
              }
            ) 
        .then(res => { 
            if(res.data.res_code===200){
                setSearch( 
                    {
                        ...search,
                        page: res.data.page,
                        limit: res.data.limit,
                        total_count: res.data.total_count,
                    }
                );

                if(res.data.data===null){
                    setCallBackData([]);
                }else{
                    setCallBackData(res.data.data);
                }

                
            }else{
                if(res.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(res.data.res_msg);
                }
            }
        });
        
    }

    function handleEdit(ev,line_idx) {
        let uri  = '/callback/'+line_idx;
        //ev.preventDefault();
        history.push(uri);
    }

    function handleRemove(){
       
      
        const arr_rem = [];
        callBackData.map(
            line =>{
                if(line.checked){
                    arr_rem.push(line.line_idx);
                    //setRemoveIdx([...removeIdx, corp.corp_idx]);
                }
                
            }
        );

        if(arr_rem.length===0){
            alert('체크박스를 선택해주세요');
            return;
        }

        if(!window.confirm('삭제하시겠습니까?')){
            return;
        }

        let json = {"line_idx":arr_rem};
        
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/callbacks/delete',json
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        ) 
        .then(response => { 
            if(response.data.res_code===200){                
                searchList(search.page);

            }else{
                if(response.data.res_code===401){
                    history.push('/login');
                }else{
                    alert(response.data.res_msg);
                }
                
            }
            
            
        });
    }

    function setSearchData(name,value){
        setSearch( 
            {
                ...search,
                [name]:value
            }
        );
    }

    function printExcel(){
        let url_path = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/xls/corp/list'
       url_path+="?corp_name="+search.corp_name;
       url_path+="&line_number="+search.line_number;
       url_path+="&local_number="+search.local_number;

        window.open(url_path,'_blank');
    }


    return(
        <div style={{position: 'relative', height: 'calc(100% - 60px)'}}>
            <SearchCallback search={search} setSearchData={setSearchData} searchList={searchList} key_name='line' />
            <PageCount search={search} setSearchData={setSearchData}/>
            <Table hover className="callbackTable">
                <thead>
                    <tr style={{width: "100%"}}>
                        <th style={{width: "2%"}} ></th>
                        <th style={{ width: "5%" }} >idx</th>
                        <th style={{}} >Callback 이름</th>
                        <th style={{ }}>Callback IP주소</th>
                        <th>Callback URL</th>
                        <th>Callback 포트</th>
                        <th>사용여부</th>
                        <th>등록자</th>
                        <th>등록일자</th>                         
                        <th></th>  
                    </tr>
                </thead>
       
                <CallbackList info={callBackData} handleEdit={handleEdit}/>
        

            </Table>
            <div className="paginationPosition">
                <div style={{float: "left"}}>
                    <PageNav search={search} searchList={searchList} setSearchData={setSearchData} />                    
                    <RePagination searchList={searchList} setSearchData={setSearchData} currentPage={search.page} count={search.total_count} pageCount={search.total_page_cnt} />
                </div>
                <div style={{float: "right"}}>
                {/* <Button color="secondary" size="sm" onClick={() => printExcel()} >엑셀출력</Button>
                &nbsp;&nbsp; */}
                <Button color="secondary" size="sm" onClick={(e) => handleEdit(e,0)} >신규등록</Button>
                &nbsp;&nbsp;
                <Button color="secondary" size="sm" onClick={(e) => handleRemove()}> &nbsp;&nbsp;삭제&nbsp;&nbsp;  </Button>
                </div>
            </div>
        </div>
    );
}

export default CallbackTable;