import { Button, ButtonGroup, InputGroup, Input, FormGroup, Col } from 'reactstrap';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../../css/search.css"
import {useLocation} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function SearchSms(props){

    let location = useLocation();

    const handleSearch = (e)  => {
        props.searchList(1);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            handleSearch(e); 
        } 
    }

    const dateFunc = (mode) => {
         props.dateFunc(mode, props.search.from, props.search.to);        
    }

    return (

       <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{marginBottom: '20px'}}><h3>SMS목록</h3></Col>
            <Col sm={12} >
                <div className="date_picker_div" style={{ display: 'flex' , flexFlow:'row nowrap' , width:'100%' , justifyContent:'flex-start' }}>
                    <DatePicker className="date_picker form-control" style={{ width:'150px' }} dateFormat="yyyy-MM-dd" id='from' selected={props.search.from} onChange={(date) => props.setSearchData("from", date)} />
                        <span style={{lineHeight: '33px'}}>&nbsp; ~ &nbsp;</span>
                    <DatePicker className="date_picker form-control" style={{ width:'150px' }} dateFormat="yyyy-MM-dd" id='to' selected={props.search.to} onChange={(date) => props.setSearchData("to", date)} />
                    
                    <ButtonGroup className="ms-1" style={{ whiteSpace:'nowrap' }}>
                        <Button type="button" onClick={(e) => dateFunc('prevDay')}>«</Button>
                        <Button type="button" onClick={(e) => dateFunc('nowDay')}>당일</Button>
                        <Button type="button" onClick={(e) => dateFunc('nextDay')}>»</Button>
                    </ButtonGroup>

                    <ButtonGroup className="ms-1" style={{ whiteSpace:'nowrap' }}>
                        <Button type="button" onClick={(e) => dateFunc('prevMonth')}>«</Button>
                        <Button type="button" onClick={(e) => dateFunc('nowMonth')}>당월</Button>
                        <Button type="button" onClick={(e) => dateFunc('nextMonth')}>»</Button>
                    </ButtonGroup>
                    &nbsp;&nbsp;&nbsp;&nbsp;                
                    <InputGroup className="ms-1" style={{ width:'auto' }}>
                        <div style={{position: 'relative', display: 'flex'}}>
                            <Input type="text" className="search_box" id="searchData"  value={props.search.searchData} placeholder="고객사정보" onChange={(e) => props.setSearchData("searchData",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
                            {
                                props.search.searchData.length ?
                                    <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("searchData", '')} />
                                : null
                            }
                        </div>
                        <div style={{position: 'relative', display: 'flex'}}>
                            <Input type="text" className="search_box" id="peer_number"  value={props.search.peer_number} placeholder="상대방번호" onChange={(e) => props.setSearchData("peer_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>                        
                            {
                                props.search.peer_number.length ?
                                    <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("peer_number", '')} />
                                : null
                            }
                        </div>
                        <Button className="btn" onClick={(e) => handleSearch(e)} >
                            검색
                        </Button>
                    </InputGroup>  
                </div>
            </Col>
        </FormGroup>

        // <div style={{float: "right" ,padding: "10px"}}>
        //    <div className="date_picker_div">
        //     <DatePicker className="date_picker" dateFormat="yyyy-MM-dd" id='from' selected={props.search.from} onChange={(date) => props.setSearchData("from",date)} />
        //     </div>
        //     <div style={{float: "left"}}> ~ </div>
        //      <div className="date_picker_div">
        //      <DatePicker className="date_picker" dateFormat="yyyy-MM-dd" id='to' selected={props.search.to} onChange={(date) => props.setSearchData("to",date)} />
        //      </div>
            
           
        //      <input type="text" className="search_box" id="peer_number"  value={props.search.peer_number} placeholder="상대방번호" onChange={(e) => props.setSearchData("peer_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>

        //      <input type="text" className="search_box" id="line_number"  value={props.search.line_number} placeholder="전화번호" onChange={(e) => props.setSearchData("line_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
          
        //    <input type="text" className="search_box" id="local_number"  value={props.search.local_number} placeholder="내선번호" onChange={(e) => props.setSearchData("local_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
           

        //     <input type="text" className="search_box" id="corp_name"  value={props.search.corp_name} placeholder="고객사명" onChange={(e) => props.setSearchData("corp_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
           
        //    <Button color="secondary" size="sm" onClick={(e) => handleSearch(e)} > &nbsp;&nbsp;검색&nbsp;&nbsp;  </Button>
        // </div>

    );
}

export default SearchSms;