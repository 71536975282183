import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
import editImg from '../../assets/modify2.png';
import { Tooltip } from 'react-tippy';
import { replaceNumber } from '../../util/StrUtil';

function OneLine({ item, nowPage, searchData, count, handleEdit, handleList, checkedline, setCheckedline }) {
    
    item.checked=false;
    function onCorpList(e){        
        handleList(item.corp_idx);
    };

    function onEdit(e){
        handleEdit(e,item.line_idx, nowPage, searchData);
    };


    function handleChecked(e){
        item.checked=!item.checked;
        if(!e.target.checked) setCheckedline(checkedline.filter(el => el !== item.line_idx))
        else setCheckedline([...checkedline, item.line_idx])
    }

    function timeCheck(val) { 

        if (val < 10) { 
            val = "0" + val;
        }

        return val;
    }

    const [shortmemo, setShortmemo] = useState(
        // item.line_memo!==null ? String(item.line_memo).split('\n').join('<br/>') : '';
        item.line_memo!==null ? String(item.line_memo) : ''
    );

    return (
        <tr style={{verticalAlign: 'middle'}}>
            <th scope="row" style={{width: "2%"}}>
                <Input type="checkbox" id="chk" onChange={(e) => handleChecked(e)} checked={checkedline.includes(item.line_idx)} />
            </th>
            <th style={{width: "3%"}}>{item.line_idx}</th>
            <td className="pointer" onClick={(e) => onCorpList(e)}>
                {item.corp_name}
            </td>
            <td className="pointer" onClick={(e) => onEdit(e)}>
                {
                    shortmemo && shortmemo !== "" ?
                        <Tooltip html={(<p style={{whiteSpace: 'pre'}}>{shortmemo}</p>)} position="bottom" trigger="mouseenter" style={{display: 'block', width:'100%', height:'100%', whiteSpace: 'pre'}}>
                        {item.line_name}
                        </Tooltip>
                    : item.line_name
                }

            </td>
            <td style={{width: "7%"}} className="pointer" onClick={(e) => onEdit(e)}>
                {/* <Tooltip title={shortmemo} position="bottom" trigger="mouseenter" style={{display: 'block', width:'100%', height:'100%', whiteSpace: 'pre'}}> */}
                {replaceNumber(item.line_number)}
                {/* </Tooltip> */}
            </td>
            <td style={{width: "7%"}} className="pointer">
                <Link to={{                
                    pathname: `/line/${item.line_idx}`,                
                    state: { nowPage }                
                }}>                    
                    {/* <Tooltip title={shortmemo} position="bottom" trigger="mouseenter" style={{display: 'block', width:'100%', height:'100%', whiteSpace: 'pre'}}> */}
                    {item.local_number} 
                    {/* </Tooltip>                    */}
                </Link>
            </td>
            <td style={{width: "5%"}}>{item.polling_period}</td>
            <td style={{width: "7%"}}>{timeCheck(item.open_door)}:{timeCheck(item.open_door_min)}-{timeCheck(item.close_door)}:{timeCheck(item.close_door_min)}</td>
            <td style={{width: "7%"}}>{item.sms_yn === "Y" ? "사용" : "사용안함"}</td>
            <td style={{width: "5%"}}>{item.acc_auth}</td>
            <td style={{width: "5%"}}>{item.exe_auth}</td>
            <td style={{width: "10%"}}>{item.last_callback_time}</td>
            <td style={{width: "10%"}}>{item.last_record_time}</td>
            <td style={{width: "5%", color: item.use_yn === "N" ? 'red' : ''}}>{item.use_yn === "Y" ? "사용" : "사용안함"}</td>
            <td style={{width: "5%", color: item.discd === 1 ? 'red' : ''}}>{item.discd === 0 ? "사용" : "해지"}</td>
            <td style={{width: "3%"}}>
                <img src={editImg} alt="수정" onClick={(e) => onEdit(e)}></img>
            </td>
        </tr>
    );

}

export default OneLine;