
import { useEffect } from 'react';
import OneRecord from './OneRecord'


function RecordList({info  ,handleEdit}){

    const hasScrollbar = () => {
        const tablebody = document.querySelector('tbody');
        if(info.length*35 <= tablebody.clientHeight) tablebody.style.width = '100%'
        else tablebody.style.width = 'calc(100% + 10px)'
    }

    useEffect(() => {
        hasScrollbar();
        window.addEventListener('resize', hasScrollbar);
        return () => {
            window.removeEventListener('resize', hasScrollbar);
        }
    }, [info])

    return (
        <tbody>
            { info.length === 0 ? 
                <tr>
                    <td colSpan='12'>통화 녹취 내역이 존재하지 않습니다.</td>
                </tr>
            :
                info.map( record => {
                    return(
                       <OneRecord key={record.record_idx} item={record}  handleEdit={handleEdit} />
                    );
                })
            }
        </tbody>
    );

}

export default RecordList;