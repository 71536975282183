
export function showPhone(number){
    let rtn_number = number;
    if(number.length===11){
        rtn_number = number.substring(0,3)+"-"+number.substring(3,7)+"-"+number.substring(7);
    }else if(number.length===10){
        rtn_number = number.substring(0,2)+"-"+number.substring(2,6)+"-"+number.substring(6);
    }else if(number.length===9){
        rtn_number = number.substring(0,2)+"-"+number.substring(2,5)+"-"+number.substring(5);
    }
    return rtn_number;
}

export function showDate(date_time){
    let rtn_date = date_time;
    if(rtn_date.length===19){
        rtn_date = rtn_date.substring(5);
   
    }
    return rtn_date;
}



/******* 2022-08-12 추가 : 단순히 표시되는 전화번호 하이픈 삽입 *******/
export function replaceNumber(name) {
    if (name !== undefined && name.length > 0 ) {       
  
      //5588248
      if (name.length == 7) {
  
          return name.substr(0, 3) + '-' + name.substr(3, 7);
          
      } else if (name.length == 8) {
          //16618290
          return name.substr(0, 4) + '-' + name.substr(4, 8);
  
      } else if (name.length == 9) {
          // 025588248
          return name.substr(0, 2) + '-' + name.substr(2, 3) + '-' + name.substr(5, 9);
  
      } else if (name.length == 10) {
          //0216618290
          // return name.substr(0, 2) + '-' + name.substr(2, 4) + '-' + name.substr(6, 4);
  
          if(name.indexOf('02')==0) return name.substr(0, 2) + '-' + name.substr(2, 4) + '-' + name.substr(6, 4);
          else return name.substr(0, 3) + '-' + name.substr(3, 3) + '-' + name.substr(6, 4);
  
      } else if (name.length == 11) {
          if(name.indexOf('050')==0) return name.substr(0, 4) + '-' + name.substr(4, 3) + '-' + name.substr(7, 4);
          //01075084129
          else return name.substr(0, 3) + '-' + name.substr(3, 4) + '-' + name.substr(7, 4);
      } else { 
          return name;
      }
  
    }else {
        return name;
    }          
  }