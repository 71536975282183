import { Button, InputGroup, Input, FormGroup, Col } from 'reactstrap';
import "../../css/search.css"
import {useLocation} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function SearchAgent(props) {
    
    let location = useLocation();

    const handleSearch = (e)  => {
        props.searchList(1);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            handleSearch(e); 
        } 
      }

    return (

        <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{marginBottom: '20px'}}><h3>Agent목록</h3></Col>
            <Col sm={3}>
                <InputGroup>
                    <div style={{position: 'relative', display: 'flex', flex: 1}}>
                        <Input type="text" className="search_box" id="searchData"  value={props.search.searchData} placeholder="회사명, 사용자명, 전화번호 또는 휴대폰을 입력하세요." onChange={(e) => props.setSearchData("searchData",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)} style={{width: '100%'}} />                    
                        {
                            props.search.searchData.length ?
                                <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("searchData", '')} />
                            : null
                        }
                    </div>
                    <Button onClick={(e) => handleSearch(e)} >
                    검색
                    </Button>
                </InputGroup>                    
                {/* <input  />           
                <Button color="secondary" className="btn" size="sm" onClick={(e) => handleSearch(e)} >검색</Button> */}
            </Col>
         </FormGroup>


        // <div style={{float: "right" ,padding: "10px"}}>
        //   <input type="text" className="search_box" id="agent_name"  value={props.search.agent_name} placeholder="사용자명" onChange={(e) => props.setSearchData("agent_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
        //    <input type="text" className="search_box" id="office_name"  value={props.search.office_name} placeholder="회사명" onChange={(e) => props.setSearchData("office_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
        //    <Button color="secondary" size="sm" onClick={(e) => handleSearch(e)} > &nbsp;&nbsp;검색&nbsp;&nbsp;  </Button>
        // </div>

    );
}

export default SearchAgent;
