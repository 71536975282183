import Cookies  from 'universal-cookie'

const cookies = new Cookies();

export const setCookie = (name , value , option) =>{
    return cookies.set(name,value,{...option});
}

export const getCookie = ( name ) =>{    
    return cookies.get(name);
}

export const getAllCookies = () =>{    
    return cookies.getAll();
}

export const isValidCookie = (name) =>{
    let acc_token = getCookie(name);
}

export const removeCookie = ( name ) => {
    return cookies.remove(name, {path : "/"});
}

export const deleteAllCookies = (type) =>{
    let cookie_split = cookies.getAll();

    for (var name in cookie_split) {
        if (type==="true") {
            if (name!=="remember_yn" && name!=="corp_number" && name!=="line_number") {
                removeCookie(name);
            }
        }
        else {
            removeCookie(name);
        }
    }
}

export const getToken = (token) => {    

    if (token) {
        let acc_tokenArray = token.split('.')[1];
        let payload = Buffer.from(acc_tokenArray, 'base64');
        let acc_token_res = JSON.parse(payload.toString());
        return acc_token_res;
    } else {
        return;
    }
    
}