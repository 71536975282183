import { Button, InputGroup, Input, FormGroup, Col, ButtonGroup } from 'reactstrap';

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "../../css/search.css"

import { useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function SearchStatMonth(props) {
    
    let location = useLocation();

    const handleSearch = (e)  => {
        props.searchList(1);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            handleSearch(e); 
        } 
    }

    const dateFunc = (mode) => {
        props.dateFunc(mode, props.search.req_month);        
    }

    let week = new Array('일', '월', '화', '수', '목', '금', '토');
    function dateFormat(date) {
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let weekday = date.getDay();
        
        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        const todayLabel = week[weekday];

        return date.getFullYear() + '-' + month + '-' + day + ' (' + todayLabel + ') ';
    }

    const searchMonth = props.search.req_month.getMonth()+1;
    const todayMonth = new Date().getMonth() + 1;

    return (

        <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{marginBottom: '20px'}}><h3>월별통계</h3></Col>
            <Col sm={5} style={{ display:'flex' }}>
                <div className="date_picker_div" style={{ display:'flex', justifyContent:'flex-start' }}>
                    <DatePicker className="date_picker form-control" dateFormat="yyyy-MM" id='req_month' selected={props.search.req_month} onChange={(date) => props.setSearchData("req_month",date)} readOnly={true} />
                    <ButtonGroup className="ms-1" style={{ whiteSpace:'nowrap' }}>
                        <Button type="button" onClick={(e) => dateFunc('prevMonth')}>«</Button>
                        <Button type="button" onClick={(e) => dateFunc('nowMonth')}>당월</Button>
                        <Button type="button" onClick={ searchMonth === todayMonth ? null : (e) => dateFunc('nextMonth')} style={searchMonth === todayMonth ? {background: 'rgba(92, 99, 106, 0.5)', border: 'rgba(92, 99, 106, 0.5)', cursor: 'auto'} : null} >»</Button>
                    </ButtonGroup>
                </div>
                <InputGroup>
                    <div style={{position: 'relative', display: 'flex', flex: 1}}>
                        <Input type="text" className="search_box" id="corp_name" value={props.search.corp_name} placeholder="고객사명" onChange={(e) => props.setSearchData("corp_name", e.target.value)} onKeyPress={(e) => handleKeyPress(e)} style={{width: '100%'}} />                    
                        {
                            props.search.corp_name.length ?
                                <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => props.setSearchData("corp_name", '')} />
                            : null
                        }
                    </div>
                    <Button className="btn" onClick={(e) => handleSearch(e)} >
                    검색
                    </Button>
                </InputGroup>                    
                {/* <input  />           
                <Button color="secondary" className="btn" size="sm" onClick={(e) => handleSearch(e)} >검색</Button> */}
            </Col>
            <Col style={{lineHeight: '33.5px', fontSize: '14px', textAlign: 'right', marginRight: '20px'}}>{dateFormat(new Date())} 기준</Col>
        </FormGroup>
        

        // <div style={{float: "right" ,padding: "10px"}}>
        //     <div className="date_picker_div">
        //     <DatePicker className="date_picker" dateFormat="yyyy-MM" id='req_month' selected={props.search.req_month} onChange={(date) => props.setSearchData("req_month",date)} />
        //     </div>
            
           
        //     <input type="text" className="search_box" id="corp_name"  value={props.search.corp_name} placeholder="고객사명" onChange={(e) => props.setSearchData("corp_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
           
        //    <Button color="secondary" size="sm" onClick={(e) => handleSearch(e)} > &nbsp;&nbsp;검색&nbsp;&nbsp;  </Button>
        // </div>

    );
}

export default SearchStatMonth;