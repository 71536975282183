import React, { useEffect, useState } from 'react';
import { Button, Input, Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { getCookie } from '../../ReactCookie';
import { Tooltip } from 'react-tippy';


function OneAuthCentrix({corp_idx , item , count , total_count , array_count, page, limit ,handleEdit, searchList, checkedline, setCheckedline}){

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  function goToCentrix() {
    setLoading(true)
    // console.log('고센 크롤러 페이지 이동')
    axios.post(`${process.env.REACT_APP_PUBLIC_NODE_URL}api/run-centrix`, {
      id: item.line_number,
      pass: item.fresh_pwd,
    })
      .then((response) => {
        setLoading(false)
        // console.log('response.data ', response.data); // 퍼펫티어 실행 결과 반환
        const result = response.data;
        if(result[0].name === 'PHPSESSID'){
          sendSessid(result.find(el => el.name === "PHPSESSID").value);
        }
        if(result[0].name === 'isLogin'){
          alert('고급형 센트릭스 홈페이지 로그인에 실패했습니다.')
        }
        if(result[0].name === 'isCaptcha'){
          alert('보안문자인증(CAPTCHA) 실패')
        }
        if(result[0].name === 'changePassword'){
          alert('고급형 센트릭스 홈페이지에서 비밀번호 변경 진행')
        }
        // if(typeof(result) !== Array &&  result.name === '"TimeoutError"') setSessid('TimeoutError')
      })
      .catch((error) => {
        setLoading(false)
        console.log('❌node js 실패 넘어옴', error);
        alert(`${`고객사명: `+item.corp_name+'\n고센 대표번호: '+item.line_number}\n\n고급형 센트릭스 웹페이지 인증실패 (${error})`)
      })
  }

  function sendSessid(sessid) {
    const data = {
      corp_idx: corp_idx,
      line_number: item.line_number,
      centrix_session_id: sessid,
    }

    axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/centrix/session', data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ getCookie("access_token")
        }
      }
    )
      .then(res => {
        // console.log(res)
        if(res.data.res_code===200) searchList(page);
        else {
          if(res.data.res_code===401) history.push('/login');
          else alert(res.data.res_msg);
        }
      })
      .catch(err => {
        console.log(err)
        alert('Session 인증 실패');
      })
  }

  function handleChecked(e){
    item.checked=!item.checked;
    if(!e.target.checked) setCheckedline(checkedline.filter(el => el !== item))
    else setCheckedline([...checkedline, item])
  }

  function timeCheck(val) { 
    if (val < 10) val = "0" + val;
    return val;
  }

  function copyText(e, val) {
    let tempElem = document.createElement('textarea');
    tempElem.value = val;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);

    const range = document.createRange();
    range.selectNodeContents(e.target);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    if(selection.rangeCount) setChangeText(true)
  }

  const [changeText, setChangeText] = useState(false);
  

  return (
    <tr style={{verticalAlign: 'middle'}}>
      <th scope="row" style={{width: "2%"}}>
        <Input type="checkbox" id="chk" onChange={(e) => handleChecked(e)} checked={checkedline.includes(item)} />
      </th>
      <th style={{width: "5%"}}>{20*(page-1)+(array_count+1)}</th>
      <td style={{width: "6%"}}>
        {
          loading ? 
            <Button disabled color='danger' size='sm'>진행중 <Spinner size='sm' /></Button>
          :
            <Button id={'item-'+item.companyid+'-'+item.line_idx} 
              outline={item.captcha_yn === 'N' || item.captcha_yn === '' ? true : false} 
              color={item.captcha_yn === 'N' || item.captcha_yn === '' ? 'danger' : 'success'} size="sm" 
              onClick={goToCentrix}>
                {item.captcha_yn === 'N' || item.captcha_yn === '' ? '인증처리' : '인증완료'}
            </Button>
        }
      </td>
      <td style={{width: "5%"}}>{item.corp_idx}</td>
      <td style={{width: "5%"}}>{item.companyid}</td>
      <td>{item.corp_name}</td>
      <td>
        <Tooltip html={(<span style={{whiteSpace: 'pre'}}>{changeText ? '복사완료 ✔' : '클릭하여 복사'}</span>)} 
          style={{display: 'inline',  height:'100%', whiteSpace: 'pre'}} theme="light" size='small' position="bottom"
          followCursor='true' trigger="mouseenter" hideOnClick={false} onHide={() => setChangeText(false)}>
          <span style={{cursor: 'pointer'}} onClick={(e) => copyText(e, item.line_number)}>{item.line_number}</span>
        </Tooltip>
      </td>
      <td>
        <Tooltip html={(<span style={{whiteSpace: 'pre'}}>{changeText ? '복사완료 ✔' : '클릭하여 복사'}</span>)} 
          style={{display: 'inline',  height:'100%', whiteSpace: 'pre'}} theme="light" size='small' position="bottom"
          followCursor='true' trigger="mouseenter" hideOnClick={false} onHide={() => setChangeText(false)}>
          <span style={{cursor: 'pointer'}} onClick={(e) => copyText(e, item.fresh_pwd)}>{item.fresh_pwd}</span>
        </Tooltip>
      </td>
      <td>{timeCheck(item.open_door)}:{timeCheck(item.open_door_min)}-{timeCheck(item.close_door)}:{timeCheck(item.close_door_min)}</td>
      <td style={{color: `${item.captcha_yn === 'N' || item.captcha_yn === '' ? 'red' : ''}`}}>{item.captcha_yn === 'N' || item.captcha_yn === '' ? '미인증' : '인증'}</td>
      <td>{item.last_captcha_time || '-'}</td>
      <td>{item.centrix_expiration_time || '-'}</td>
      <td>{item.last_captcha_id || '-'}</td>
    </tr>
  )
}

export default OneAuthCentrix;