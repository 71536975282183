import { Button, InputGroup, Input, FormGroup, Col } from 'reactstrap';
import "../../css/search.css"
import {useLocation} from 'react-router-dom'

function SearchCallback(props) {
    
    let location = useLocation();

    const handleSearch = (e)  => {
        props.searchList(1);
    }

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            handleSearch(e); 
        } 
      }

    return (

        <FormGroup row className="pt-3 pb-3">
            <Col sm={12} style={{marginBottom: '25px'}}><h3>콜백목록</h3></Col>
            {/* 
            <Col sm={4}>
                <InputGroup>
                    <Input type="text" className="search_box form-control" id="line_number"  value={props.search.line_number} placeholder="전화번호" onChange={(e) => props.setSearchData("line_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>          
                    <Input type="text" className="search_box form-control" id="local_number"  value={props.search.local_number} placeholder="내선번호" onChange={(e) => props.setSearchData("local_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
                    <Input type="text" className="search_box form-control" id="corp_name"  value={props.search.corp_name} placeholder="고객사명" onChange={(e) => props.setSearchData("corp_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>                              
                    <Button onClick={(e) => handleSearch(e)} >
                    검색
                    </Button>
                </InputGroup>                                    
        </Col>
         */}
         </FormGroup>

        // <div style={{float: "right" ,padding: "10px"}}>
        //     <input type="text" className="search_box" id="line_number"  value={props.search.line_number} placeholder="전화번호" onChange={(e) => props.setSearchData("line_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
          
        //   <input type="text" className="search_box" id="local_number"  value={props.search.local_number} placeholder="내선번호" onChange={(e) => props.setSearchData("local_number",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
          

        //    <input type="text" className="search_box" id="corp_name"  value={props.search.corp_name} placeholder="고객사명" onChange={(e) => props.setSearchData("corp_name",e.target.value)} onKeyPress={(e)=>handleKeyPress(e)}/>
          
        //    <Button color="secondary" size="sm" onClick={(e) => handleSearch(e)} > &nbsp;&nbsp;검색&nbsp;&nbsp;  </Button>
        // </div>

    );
}

export default SearchCallback;
