import React, { useState } from 'react';
import { Input } from 'reactstrap';
import editImg from '../../assets/modify2.png';
import { Tooltip } from 'react-tippy';
import { replaceNumber } from '../../util/StrUtil';


function OneCorp({corp_idx , item , count , total_count , array_count, page, limit ,handleEdit}){
   
    item.checked=false;
    function onEdit(e){
        handleEdit(e,item.corp_idx);
    };

    function handleChecked(event){
        item.checked=!item.checked;
    }

    function timeCheck(val) { 

        if (val < 10) { 
            val = "0" + val;
        }

        return val;
    }

    const [shortmemo, setShortmemo] = useState(
            // item.corp_memo!==null ? String(item.corp_memo).split('\n').join('<br/>') : ''
            item.corp_memo!==null ? String(item.corp_memo) : ''
    );


    return (
        <tr style={{verticalAlign: 'middle'}}>
            <th scope="row" style={{width: "2%"}}><Input type="checkbox" id="chk"  onClick={(e) => handleChecked(e)} /></th>
            <th style={{width: "5%"}}>{corp_idx}</th>
            <td style={{ cursor:'pointer' }} onClick={(e) => onEdit(e)}>
                {
                    shortmemo && shortmemo !== "" ?
                        <Tooltip html={(<p style={{whiteSpace: 'pre'}}>{shortmemo}</p>)} position="bottom" trigger="mouseenter" style={{display: 'block', width:'100%', height:'100%', whiteSpace: 'pre'}}>
                        {item.corp_name}
                        </Tooltip>
                    : item.corp_name
                }

              </td>
            <td>{replaceNumber(item.corp_number)}</td>
            <td style={{width: "8%"}}>{item.line_count}</td>
            <td style={{width: "8%"}}>{item.polling_period}</td>
            <td style={{width: "8%"}}>{item.save_days}</td>
            <td style={{width: "10%"}}>{timeCheck(item.open_door)}:{timeCheck(item.open_door_min)}-{timeCheck(item.close_door)}:{timeCheck(item.close_door_min)}</td>
            <td style={{width: "10%"}}>{item.agent_name}</td>
            <td style={{width: "10%"}}>{item.reg_date}</td>
            <td style={{width: "5%", color: item.use_yn === "N" ? 'red' : ''}}>{item.use_yn === "Y" ? '사용' : '사용안함'}</td>
            <td style={{width: "5%", color: item.discd === 1 ? 'red' : ''}}>{item.discd === 0 ? '등록' : '해지'}</td>
            <td style={{width: "5%"}}>
                <img src={editImg} alt="수정" onClick={(e) => onEdit(e)}></img>
            </td>
        </tr>
    );
}

export default OneCorp;
