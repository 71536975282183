import {useState, useEffect} from "react";
import { Form, FormGroup, Label, Input, Col ,Button } from 'reactstrap';
import axios from 'axios'

import { useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";

import { confirmAlert } from "react-confirm-alert";

const blank_data={
    agent_idx: 0,
    office_name: "",
    agent_name: "",
    office_number: "",
    mobile_number: "",
    email_addr: "",
    use_yn: "Y",
    memo: "",
    reg_date: "",
    checked: false

}

function AgentDetail(props){
    const [ data, setData ] = useState(blank_data);
    const history = useHistory();

    useEffect(() => {
        const historyState = history.listen((location, action) => {
            if (action === 'POP'){
                return goList();
            }
            return true;
        })
        return () => historyState();
    }, [])

    useEffect( () => {

        if(props.match.params.agent_idx>0){
                
            let uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/agent/'+props.match.params.agent_idx;

            axios.get(uri
                ,{ 
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+getCookie("access_token")
                    }
                }    
            ) 
            .then(res => { 
                if(res.data.res_code===200){
                    setData(res.data.data);
                }else{
                    if(res.data.res_code===401){
                        history.push('/login');
                    }else{
                        alert(res.data.res_msg);
                    }
                }
            });
        }
    },[]);

    function valueChange(e){
        setData( 
            {
                ...data,
                [e.target.name]:e.target.value
            }
        );
    }

    function handleUpdate() {
        let alertmsg = "";
        if(data.agent_idx > 0) {
            alertmsg = 'Agent 정보를 수정하였습니다.'
        } else {
            alertmsg = '신규 Agent 정보를 등록하였습니다.'
        }
	
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/agent/',data
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        ) 
        .then(response => { 
            if(response.data.res_code===200){
               
                setData(response.data.data);
                confirmAlert({message: alertmsg, buttons: []})
                setTimeout(() => {
                    if(document.querySelector('.react-confirm-alert-overlay')) document.querySelector('.react-confirm-alert-overlay').click();
                }, 1200) 
            }else{
                alert(response.data.res_msg);
            }
            
            
        });
        
    }
    
    function goList(){
        // history.goBack();
        history.push({pathname: '/agents', state: {...props.location.state}})
    }


    return(
        <div style={{paddingTop: '15px' ,paddingBottom: '15px'}}>
        <Form>
            <FormGroup row>
            <Label for="api_idx" sm={2}>색인</Label>
            <Col sm={10}>
            <Input type="text"  name="agent_idx" id="agent_idx" value={data.agent_idx} readOnly="readonly" placeholder="색인번호입니다" onChange={(e) => valueChange(e)}/>
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="corpName" sm={2}>회사명</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:true}} name="office_name" id="office_name"  value={data.office_name} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="line_number" sm={2}>사용자명</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:true}} name="agent_name" id="agent_name" value={data.agent_name} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>
	    
            <FormGroup row>
            <Label for="line_number" sm={2}>회사전화번호</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="office_number" id="office_number" value={data.office_number} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="line_number" sm={2}>휴대폰</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="mobile_number" id="mobile_number" value={data.mobile_number} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

	<FormGroup row>
            <Label for="line_number" sm={2}>이메일</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="email_addr" id="email_addr" value={data.email_addr} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

	    <FormGroup row>
            <Label for="ip200" sm={2}>사용여부</Label>
            <Col sm={10}>
            <Input type="select" name="use_yn" id="use_yn" value={data.use_yn} onChange={(e) => valueChange(e)}>
                    <option value="Y" >Y</option>
                    <option value="N" >N</option>
                </Input>
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="api_time" sm={2}>등록일</Label>
            <Col sm={10}>
            <Input type="text" name="reg_date" id="api_time" value={data.reg_date} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

	<FormGroup row>
            <Label for="memo" sm={2}>메모</Label>
            <Col sm={10}>
            <Input type="textarea" name="memo" id="memo" value={data.memo} onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>

            <p>
            </p>
            
            {/* <FormGroup check row>
            <Col sm={{ size: 10, offset: 2 }}>
            <Button onClick={() => handleUpdate()}>Submit</Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button onClick={() => goList()}>목록으로</Button>
            </Col>
            </FormGroup> */}
            
            <FormGroup check row className="mb-1">
            {/* <Col sm={{ size: 10, offset: 2 }}> */}
            <Col sm={12} className="text-end">
            <Button onClick={() => handleUpdate()}>
                { data.agent_idx > 0 ? "수정" : "등록"}
            </Button>
            <Button className="ms-2" onClick={() => goList()}>목록</Button>
            </Col>
            </FormGroup>
        </Form>
        </div>
    );
}

export default AgentDetail;