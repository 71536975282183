import {useState, useEffect} from "react";
import { Form, FormGroup, Label, Input, Col ,Button } from 'reactstrap';
import axios from 'axios'

import { Link, useHistory } from "react-router-dom";
import { getCookie } from "../../ReactCookie";

import { confirmAlert } from "react-confirm-alert";

const blank_data={
    admin_idx: 0,
    admin_id: "",
    admin_name: "",
    fresh_pwd: "",
    reg_date: "",
    last_date: "",
    checked: false

}

function AdminDetail(props){
    const [ data, setData ] = useState(blank_data);
    const history = useHistory();

    useEffect(() => {
        const historyState = history.listen((location, action) => {
            if (action === 'POP'){
                return goList();
            }
            return true;
        })
        return () => historyState();
    }, [])

    useEffect( () => {

        if(props.match.params.admin_idx>0){

            let uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/adm/'+props.match.params.admin_idx;

            axios.get(uri
                ,{ 
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+getCookie("access_token")
                    }
                }    
            ) 
            .then(res => { 
                if(res.data.res_code===200){
                    setData(res.data.data);
                }else{
                    if(res.data.res_code===401){
                        history.push('/login');
                    }else{
                        alert(res.data.res_msg);
                    }
                }
            });
        }

    },[]);

    function valueChange(e){
        setData( 
            {
                ...data,
                [e.target.name]:e.target.value
            }
        );
    }

    function handleUpdate() {	
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/adm/',data
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        ) 
        .then(response => { 
            if(response.data.res_code===200){
                //setData(blank_data );
                response.data.data.fresh_pwd="";
                setData(response.data.data);
                confirmAlert({message: "관리자 정보를 수정하였습니다.", buttons: []})
                setTimeout(() => {
                    if(document.querySelector('.react-confirm-alert-overlay')) document.querySelector('.react-confirm-alert-overlay').click();
                }, 1200)  
                
            }else{
                alert(response.data.res_msg);
            }
            
            
        });
        
    }
    
    function goList(){
        // history.goBack();
        history.push({pathname: '/adms', state: {...props.location.state}})
    }


    return(
        <div style={{paddingTop: '15px' ,paddingBottom: '15px'}}>
        <Form>
            <FormGroup row>
            <Label for="api_idx" sm={2}>색인</Label>
            <Col sm={10}>
            <Input type="text"  name="admin_idx" id="admin_idx" value={data.admin_idx} readOnly="readonly" placeholder="색인번호입니다" onChange={(e) => valueChange(e)}/>
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="corpName" sm={2}>아이디</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:false}} name="admin_id" id="admin_id"  value={data.admin_id} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

	    <FormGroup row>
            <Label for="corpPasswd" sm={2}>비밀번호(*)</Label>
            <Col sm={10}>
            <Input type="password" name="fresh_pwd" id="fresh_pwd"  value={data.fresh_pwd} placeholder="비밀번호 변경시 입력해주세요" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="line_number" sm={2}>관리자명</Label>
            <Col sm={10}>
            <Input type="text" style={{imeMode:true}} name="admin_name" id="admin_name" value={data.admin_name} placeholder="" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            <p></p>

            <FormGroup row>
            <Label for="api_time" sm={2}>등록일</Label>
            <Col sm={10}>
            <Input type="text" name="reg_date" id="api_time" value={data.reg_date} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>


            <FormGroup row>
            <Label for="api_status" sm={2}>최종로그인</Label>
            <Col sm={10}>
            <Input type="text" name="last_date" id="api_status" value={data.last_date} readOnly="readonly" onChange={(e) => valueChange(e)} placeholder="" />
            </Col>
            </FormGroup>
            <p></p>


            <p>
            </p>
            
             <FormGroup check row className="text-end mb-1 d-flex">
                 <Col sm={12}>                
                <Button onClick={() => handleUpdate()}>
                    { data.admin_idx > 0 ? "수정" : "등록"}
                </Button>
                <Button className="ms-2" onClick={() => goList()}>목록</Button>            
                </Col>
                </FormGroup>
                
            {/* <FormGroup check row>
                <Col sm={{ size: 10, offset: 2 }}>
                <Button onClick={() => handleUpdate()}>Submit</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button onClick={() => goList()}>목록으로</Button>
                </Col>
            </FormGroup> */}
        </Form>
        </div>
    );
}

export default AdminDetail;