import React ,{useState, useEffect} from "react";
import { Form, FormGroup, Label, Input, Col, Button, Alert } from 'reactstrap';

import Popup from 'react-popup';

import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios'
import { Link, useHistory, useLocation } from "react-router-dom";
import { getCookie } from "../../ReactCookie";
import {Bg, AgentSearchPopup} from "../Popup";
import { confirmAlert } from "react-confirm-alert";
import { createBrowserHistory } from "history";

const blank_data={
    corp_idx: 0,
    corp_name: "",
    corp_number: "",
    business_number: "",
    polling_period: 30,
    use_yn: "Y",
    save_days: 90,
    auth_type: "corp",
    open_door: 0,
    close_door: 0,
    open_door_min: 0,
    close_door_min: 0,
    corp_memo: "",
    agent_idx: 0,
    agent_name: "",
    companyid: 0,
    checked: false,
    discd: 0,
}

function CorpDetail(props){
    
    const [data, setData] = useState(blank_data);
    const [successPopup, setSuccessPopup] = useState(false);
    const [failPopup, setFailPopup] = useState(false);
    const [prevDiscd, setPrevDiscd] = useState(blank_data.discd);

    const [open, setOpen] = useState(false);    

    const [prevmemo, setPrevmemo] = useState('');

    //const { corp_idx } = match.params.idx;
    // const [selected ,setSelected] = useState("");

    const history = useHistory();

    useEffect(() => {
        const historyState = history.listen((location, action) => {
            if (action === 'POP') {
                return goList();
            //   history.push({pathname: '/corps', state: {searchOption: props.location.state.searchOption}});
            // history.push({pathname: '/corps', state: {searchOption: props.location.state.searchOption}})
            }
        })
        return () => historyState();
    }, [])

    useEffect(() => {

        if(props.match.params.corp_idx>0){

            let uri = process.env.REACT_APP_PUBLIC_SERVER + '/mgr/corp/'+props.match.params.corp_idx;

            axios.get(uri
                ,{ 
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+getCookie("access_token")
                    }
                }
                ) 
            .then(res => { 
                if(res.data.res_code===200){
                    setPrevDiscd(res.data.data.discd)
                    setData(res.data.data);
                    if(res.data.data.corp_memo !== null) setPrevmemo(res.data.data.corp_memo)
                }else{
                    
                    setData(blank_data);
                    setPrevDiscd(blank_data.discd)
                }
            });
        }
    },[]);

    function valueChange(e) {
        
        if (e.target.name === "open_door" || e.target.name === "close_door") {

            return e.target.value.length > 2 ? false :     
                
            setData(
            {
                ...data,
                [e.target.name]:e.target.value
            }
            );
            

        } else { 
            setData(
                {
                    ...data,
                    [e.target.name]:e.target.value
                }
            );
        }
    }

    const [updatememo, setUpdatememo] = useState(1);

    useEffect(() => {
        handleUpdate();
    }, [updatememo])

    function addMemodate() {
        const date = new Date();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();

        month = month >= 10 ? month : '0' + month;
        day = day >= 10 ? day : '0' + day;
        hour = hour >= 10 ? hour : '0' + hour;
        minute = minute >= 10 ? minute : '0' + minute;
        second = second >= 10 ? second : '0' + second;

        let memoDate =  date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;

        setData({
            ...data,
            corp_memo: data.corp_memo+ `  [${memoDate}]`
        })
        setUpdatememo(prev => prev+1)
    }

    function handleUpdate() {

        let alertmsg = "";
        if(data.corp_idx > 0) {
            alertmsg = '고객사 정보를 수정하였습니다.'
        } else {
            alertmsg = '신규 고객사 정보를 등록하였습니다.'
        }

        let submitData = {...data};
        if(data.corp_idx > 0){
            if(data.discd != prevDiscd){
                const date = new Date();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let hour = date.getHours();
                let minute = date.getMinutes();
                let second = date.getSeconds();
        
                month = month >= 10 ? month : '0' + month;
                day = day >= 10 ? day : '0' + day;
                hour = hour >= 10 ? hour : '0' + hour;
                minute = minute >= 10 ? minute : '0' + minute;
                second = second >= 10 ? second : '0' + second;
        
                let memoDate =  date.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
                submitData.corp_memo = data.corp_memo+ (data.corp_memo !== '' ? '\r\n' : '') +'관리자 '+getCookie("login_name")+': 해당 고객사 \''+(prevDiscd === 0 ? '등록' : '해지')+'>'+(data.discd === '0' ? '등록' : '해지')+`' 변경  [${memoDate}]`;
            }
        }


        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/corp',submitData
        ,{ 
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+getCookie("access_token")
            }
          }
        ) 
        .then(response => {
            if(response.data.res_code===200){
                //setData(blank_data );
                response.data.data.fresh_pwd="";
                setData(response.data.data);
                //setSuccessPopup(true);
                setPrevDiscd(response.data.data.discd)
                setPrevmemo(response.data.data.corp_memo)
                confirmAlert({message: alertmsg, buttons: []})
                setTimeout(() => {
                    if(document.querySelector('.react-confirm-alert-overlay')) document.querySelector('.react-confirm-alert-overlay').click();
                }, 1200)  
            }else{
                //alert(response.data.res_msg);
                setFailPopup(true);
            }
            
            
        });
    }

    function goList(){
        history.push({pathname: props.location.state.prevPath, state: {...props.location.state}});
        // if(props.location.state.prevPath === '/corps') history.push({pathname: '/corps', state: {searchOption: props.location.state.searchOption}});
        // if(props.location.state.prevPath === '/lines') history.push({pathname: '/lines', state: {searchOption: props.location.state.searchOption}});
        // history.goBack();
    }

    function findAgent(e){
        alert("findAgent");
    }

    let timeLoop = () => {
        let timeArray = [];
        for (let i = 0; i < 24; i++) {
            timeArray.push(<option value={i} key={i}>{i}</option>);
        }
        return timeArray;
    }

    function selectAgentData(idx, name) {
         setData(
                {
                    ...data,
                    agent_idx: idx,
                    agent_name:name,
                }
        );

        setOpen(false);
        
    }

    function initialValue(e) {
        if(!e.target.value) setData({...data, [e.target.name]: 0 })
    }

    return (
        <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
        <Form>
            <FormGroup row className="mb-1">
            <Label for="corp_idx" sm={2}>색인</Label>
            <Col sm={10}>
            <Input type="text"  name="corp_idx" id="corp_idx" value={data.corp_idx||'' } readOnly="readonly" placeholder="색인번호입니다" onChange={(e) => valueChange(e)}/>
            </Col>
            </FormGroup>
            

            <FormGroup row className="mb-1">
            <Label for="corp_name" sm={2}>고객사명(*)</Label>
            <Col sm={4}>
            <Input type="text" style={{imeMode:false}} name="corp_name" id="corp_name" value={data.corp_name||'' } placeholder="고객사명을 입력해주세요" onChange={(e) => valueChange(e)} />
            </Col>
            <Label for="fresh_pwd" sm={2}>비밀번호(*)</Label>
            <Col sm={4}>
            <Input type="password" name="fresh_pwd" id="fresh_pwd"  value={data.fresh_pwd||''} placeholder="비밀번호 변경시 입력해주세요" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>
            
            <FormGroup row className="mb-1">
            <Label for="corp_number" sm={2}>대표번호(*)</Label>
            <Col sm={4}>
            <Input type="text" style={{imeMode:false}} name="corp_number" id="corp_number" value={data.corp_number||'' } placeholder="대표번호를 입력해주세요" onChange={(e) => valueChange(e)} />
            </Col>
            <Label for="business_number" sm={2}>사업자등록번호</Label>
            <Col sm={4}>
            <Input type="text" name="business_number" id="business_number"  value={data.business_number||''} placeholder="사업자등록번호를 입력해주세요" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>


            <FormGroup row className="mb-1">
            <Label for="polling_period" sm={2}>주기(분)</Label>
            <Col sm={4}>
            <Input type="text" name="polling_period" id="polling_period" value={data.polling_period||''} onChange={(e) => valueChange(e)} placeholder="분단위 폴링주기를 입력해주세요" />
            </Col>
            <Label for="save_days" sm={2}>보관(일)</Label>
            <Col sm={4}>
            <Input type="text" name="save_days" id="save_days" value={data.save_days||''} onChange={(e) => valueChange(e)} placeholder="녹음파일 보관일을 입력해주세요" />
            </Col>
            </FormGroup>
            

            <FormGroup row className="mb-1 w-50" style={{ lineHeight:'35px' }}>
                <Label for="open_door" sm={4} style={{marginRight: '4px'}} >영업시간</Label>
                    <Col style={{width: '14.5%'}}>                        
                        <Input type="number" name="open_door" id="open_door" value={data.open_door+'' || ''} onChange={(e) => valueChange(e)} onBlur={(e) => initialValue(e)} min="0" max="24"  >
                            {/* {timeLoop()} */}
                        </Input>
                    </Col>
                    시
                    <Col style={{width: '14.5%'}}>                        
                        <Input type="number" name="open_door_min" id="open_door_min" value={data.open_door_min+'' || ''} onChange={(e) => valueChange(e)} onBlur={(e) => initialValue(e)} min="0" max="60"  >
                            {/* {timeLoop()} */}
                        </Input>                        
                    </Col>            
            {/* <Label for="Dash" sm={1}>분 ~</Label> */}
                    분 ~
                    <Col style={{width: '14.5%'}}>                                
                        <Input type="number" name="close_door" id="close_door" value={data.close_door+'' || ''} onChange={(e) => valueChange(e)} onBlur={(e) => initialValue(e)} min="0" max="24"  >
                            {/* {timeLoop()} */}
                        </Input>                        
                    </Col>
                    시
                    <Col style={{width: '14.5%'}}>                        
                        <Input type="number" name="close_door_min" id="close_door_min" value={data.close_door_min+'' || ''} onChange={(e) => valueChange(e)} onBlur={(e) => initialValue(e)}  min="0" max="60"  >
                            {/* {timeLoop()} */}
                        </Input>                        
                    </Col>   
            {/* <Label for="Dash" sm={1}>분</Label> */}
                    분
            </FormGroup>
            

                <FormGroup row className="mb-1">                    
                    <Label for="agent_name" sm={2}>Agent</Label>                    
                    <Col sm={4}>                        
                        <Input type="text" name="agent_name" id="agent_name" value={data.agent_name || ''}                            
                    // onChange={(e) => valueChange(e)}
                            onClick={() => { setOpen(true); }}                            
                            placeholder="Agent명을 선택해주세요" readOnly />                        
                        <input type="hidden" name="agent_idx" value={data.agent_idx || '0'} />                        
                    </Col>
                    <Label for="use_yn" sm={2}>사용여부</Label>
                    <Col sm={4}>                        
                        <Input type="select" name="use_yn" id="use_yn" value={data.use_yn || ''} onChange={(e) => valueChange(e)}>                            
                            <option value="Y" >사용</option>                            
                            <option value="N" >사용안함</option>
                        </Input>
                    </Col>
                </FormGroup>
            <FormGroup row className="mb-1">
            <Label for="ip200" sm={2}>IQ200 COMPANYID</Label>
            <Col sm={4}>
            <Input type="text" name="companyid" id="companyid" value={data.companyid|| ''} onChange={(e) => valueChange(e)} placeholder="IQ200 Company ID를 입력해주세요" />
            </Col>
            <Label for="discd" sm={2}>고객사 등록상태</Label>         
            <Col sm={4}>               
                <Input type="select" name="discd" id="discd" value={(data.discd|| '')} onChange={(e) => valueChange(e)}>  
                    <option value="0" >등록</option>
                    <option value="1" >해지</option>
                </Input>
            </Col>
            </FormGroup>

            <FormGroup row className="mb-1">
            <Label for="registerDay" sm={2}>등록일</Label>
            <Col sm={4}>
            <Input type="text" name="reg_date" id="reg_date" value={data.reg_date||''} readOnly="readonly" placeholder="등록일입니다" onChange={(e) => valueChange(e)} />
            </Col>
            <Label for="lastDay" sm={2}>수정일</Label>
            <Col sm={4}>
            <Input type="text" name="last_date" id="last_date" value={data.last_date||''} readOnly="readonly" placeholder="최종수정일입니다" onChange={(e) => valueChange(e)} />
            </Col>
            </FormGroup>

            <FormGroup row className="mb-1">
                <Label for="corp_memo" sm={2}>메모</Label>
                <Col sm={10}>
                <Input type="textarea" name="corp_memo" id="corp_memo" value={data.corp_memo||'' }  onChange={(e) => valueChange(e)}
                    style={{height: '200px', resize:'none', textOverflow: 'scroll'}} />
                </Col>
            </FormGroup>
            
            <FormGroup check row className="text-end mb-1 d-flex">
                 <Col sm={12}>
                    {data.corp_idx > 0 ?
                        <Link to={`/line/0?corp_name=${data.corp_name}&corp_idx=${data.corp_idx}`}>
                            <Button type="button" className="me-2">고객사 회선등록</Button>                        
                        </Link>
                : <></>}
                
                {/* <Button onClick={() => handleUpdate()}> */}
                <Button onClick={() => data.corp_memo !== '' && data.corp_memo.toString().split(' ').join('').split('\n').join('') !== prevmemo.toString().split(' ').join('').split('\n').join('') ? addMemodate() : handleUpdate()}>
                    { data.corp_idx > 0 ? "수정" : "등록"}
                </Button>
                <Button className="ms-2" onClick={() => goList()}>목록</Button>            
                </Col>
            </FormGroup>
            </Form>
        
            {open === true ? (
                <>
                    <AgentSearchPopup type={"info"} open={open} close={setOpen} timeout={1000} cookie={getCookie("access_token")} selectAgentData={selectAgentData}></AgentSearchPopup>
                    <Bg></Bg>
                </>
            ) : <>{open}</>}
    </div>        
    )
}

export default CorpDetail;
