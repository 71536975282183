
import { useEffect,useState} from 'react';
import {useLocation} from 'react-router-dom'
import { Pagination } from 'semantic-ui-react'
import left01 from '../assets/arrow_left01.gif';
import left02 from '../assets/arrow_left02.gif';
import right01 from '../assets/arrow_right01.gif';
import right02 from '../assets/arrow_right02.gif';

function PageNav(props) {
    
    // 입력 page , limit , total_count 

    // page : 현재 선택된 페이지
    // limit : 한페이지에 디스플레이되는  갯수
    // total_count : 전체 rows 카운트

    
    let show_count = 10;
    // active_page : 현재 선택된 페이지
    // show_count : 페이징에 표시되는 링크수
    // total_page_cnt : 전체 rows 를 limit 로 나눈것 (표시할수 있는 페이지 전체)
    
    // prev_begin_page : 이전 표시그룹의 첫번째 페이지
    // next_begin_page : 다음 표시그룹의 첫번째 페이지
    // prev_page : 이전페이지
    // next_page : 다음페이지

    // begin_page : 페이지 표시 시작지점

    let prev_begin_page=1;
    let next_begin_page=1;
    let prev_page=1;
    let next_page=1;
    let begin_page=1;
    
    let active_page = props.search.page;    
    if (active_page === 0) active_page = 1;

    let total_page_cnt = 0;
    let total_page_gain = parseInt(props.search.total_count/props.search.limit);
    total_page_cnt=total_page_gain;

    if((props.search.total_count%props.search.limit)>0){
        total_page_cnt++;
    }
    
    useEffect(() => {
        props.setSearchData("total_page_cnt", total_page_cnt);
    }, [total_page_cnt]);

    //console.log(props.key_name+" props.search.total_count",props.search.total_count);
    //console.log(props.key_name+" props.search.limit",props.search.limit);
    //console.log(props.key_name+" total_page_cnt",total_page_cnt);
       
    //last_page=total_page_cnt;


    let page_gain = parseInt(active_page / show_count);
    begin_page = page_gain*show_count +1;

    if(begin_page>1){
        prev_page=begin_page-1;
    }
    if(begin_page<total_page_cnt){
        next_page=begin_page+1;
    }

    if(page_gain>0){
        prev_begin_page=page_gain*show_count+1;
    }
    
    
    // next_begin_page = begin_page;
    // if((page_gain*show_count+show_count)<total_page_cnt){
    //     next_begin_page=(page_gain+1)*show_count+1;
    // }

    let page_map = [];

    //for(let zz=begin_page;zz<=total_page_cnt && zz<(zz+show_count);zz   ++){
    for(let zz=begin_page;zz<=total_page_cnt && zz<(zz+show_count);zz   ++){
        page_map.push(zz+" ");
    }


    function pageNavation(num){
        //props.setPage(num);
        props.searchList(num);
    }

    return (

        <>
            <Pagination style={{display: 'none'}}                   
                //defaultActivePage={defaultPage}                
                totalPages={total_page_cnt}                
                activePage={active_page}
                onPageChange={(event, data) => {             
                    // console.log(data.activePage);                    
                    pageNavation(data.activePage);                    
                    }
                }
            />
            {/* <div style={{ paddingBottom:'100px' }}></div>         */}
        </>
    
    /*
    <table style={{border:'0' }} >
    <tbody>
    
            <tr style={{ cursor:'pointer' }}>
                <td>
                <img src={left02} alt="처음페이지" onClick={() => pageNavation(prev_begin_page)}></img>
                </td>
                
                <td style={{padding:'0 7px' }} >
                    <img src={left01} alt="이전페이지" onClick={() => pageNavation(prev_begin_page)}></img>
                    </td>
                    {
                        
                        page_map.map((item, index, { length }) => {     
                            
                            console.log("반 " + Math.round(length/2));
                            
                            if (index <= 3) {
                                return (
                                    <td style={{ width: '20px', fontWeight: '700', fontSize: '1.0rem', color: '#303C54' }} onClick={() => pageNavation(item)} key={item}>{item}</td>
                                    )
                                } else if (index > 3 && index < Math.round(length / 2)) {
                                    return (                                    
                                        <></>
                                        )                                
                                    } else if (index == Math.round(length / 2)) { 
                                return (                                    
                                    <td style={{ width: '20px'}} onClick={() => pageNavation(item)} key={item}>...</td>
                                    )                                
                                } else if ( index > Math.round(length / 2) && index <= ( Math.round(length / 2) + 3) ) {
                                    return (
                                        <td style={{ width: '20px', fontWeight: '700', fontSize: '1.0rem', color: '#303C54' }} onClick={() => pageNavation(item)} key={item}>{item}</td>
                                        )
                                    } else if (index == Math.round(length / 2) + 4) { 
                                        return (                                    
                                            <td style={{ width: '20px'}} onClick={() => pageNavation(item)} key={item}>...</td>
                                            )                                
                                        } else if ( index > length-3 && index <= length ) {
                                            return (                                    
                                                <td style={{ width: '20px', fontWeight: '700', fontSize: '1.0rem', color: '#303C54' }} onClick={() => pageNavation(item)} key={item}>{item}</td>
                                )                                
                            }

                            if(parseInt(item) === parseInt(active_page)){
                                return (
                                        <td style={{width:'20px', fontWeight:'700', fontSize:'1.0rem', color:'#303C54', backgroundColor:'lightGray' }} onClick={() => pageNavation(item)} key={item}>{item}</td>
                                );
                            }else{
                            // console.log("item yy",item,"active_apge",active_page)
                                return (
                                    <td style={{width:'20px', fontWeight:'500', fontSize:'1.0rem', color:'#6C757D'   }} onClick={() => pageNavation(item)} key={item}>{item}</td>
                                    );
                                }
                    })
                }
                
                <td style={{padding:'0 7px' }} >
                    <img src={right01} alt="다음페이지" onClick={() => pageNavation(next_begin_page)} ></img>
                </td>
                <td>
                    <img src={right02} alt="마지막페이지" onClick={() => pageNavation(total_page_cnt)}></img>
                </td>

                </tr>
                </tbody>
                </table>
                */
                
            );
        }
        
        export default PageNav;