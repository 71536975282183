
import { useEffect } from 'react';
import OneApi from './OneApi'


function ApiList({info  ,handleEdit}){

    const hasScrollbar = () => {
        const tablebody = document.querySelector('tbody');
        if(info.length*29 <= tablebody.clientHeight) tablebody.style.width = '100%'
        else tablebody.style.width = 'calc(100% + 10px)'
    }

    useEffect(() => {
        hasScrollbar();
        window.addEventListener('resize', hasScrollbar);
        return () => {
            window.removeEventListener('resize', hasScrollbar);
        }
    }, [info])

    return (
        <tbody>
            {info.length === 0?
                <tr>
                    <td colSpan='9'>API 정보가 존재하지 않습니다.</td>
                </tr>
            :
                info.map( api =>{
                    return(
                       <OneApi key={api.api_idx} item={api}  handleEdit={handleEdit} />
                    );
                })
            }
        </tbody>
    );

}

export default ApiList;