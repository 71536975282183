import { Button, ButtonGroup, InputGroup, Input, FormGroup, Col, InputGroupText, Label } from 'reactstrap';
import "../../css/search.css"
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedoAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../../css/refresh.css';

function SearchAuthCentrix(props) {
  const [showRefresh, setShowRefresh] = useState(false);
  const [refreshOption, setRefreshOption] = useState(false);

  const handleSearch = (e)  => {
    props.setSortType({
      captcha_sort: 0,
      expiration_sort: 0,
    })
    props.searchList(1, undefined, undefined, 0, 0);
    if(props.search.captcha_yn === 'N') setShowRefresh(true)
    else setShowRefresh(false)
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") handleSearch(e); 
  }

  useEffect(() => {
    if(!showRefresh) setRefreshOption(false);
  }, [showRefresh])

  useEffect(() => {
    const intervalRefresh = setInterval(() => {
      if(refreshOption) props.searchList(1, props.search.searchData, 'N', props.sortType.captcha_sort, props.sortType.expiration_sort);
      else clearInterval(intervalRefresh);
    }, 300000);
    return () => clearInterval(intervalRefresh);
  }, [refreshOption, props.sortType])


  return (
    <FormGroup row className="pt-3 pb-3">
      <Col sm={12} style={{marginBottom: '20px'}}><h3>고객사별 대표번호 인증처리</h3></Col>
      <Col sm={4}>
        <InputGroup>
          <div style={{position: 'relative', flex: '1 1 auto'}}>
            <Input type="text" style={{width: '97%'}} className="search_box form-control" id="searchData" value={props.search.searchData} placeholder="corp_idx, companyID 또는 고객사명을 입력하세요." onChange={(e) => {props.setSearchData("searchData", e.target.value); setShowRefresh(false);}} onKeyPress={(e) => handleKeyPress(e)} />
            {
              props.search.searchData.length ?
                <FontAwesomeIcon icon={faTimes} style={{position: 'absolute', right: '20px', top: '10px', cursor: 'pointer'}} onClick={() => {props.setSearchData("searchData", ''); setShowRefresh(false);}} />
              : null
            }
          </div>
          <div>
            <Input type="select" className="search_box" id="captcha_yn" value={props.search.captcha_yn} onChange={(e) => {props.setSearchData("captcha_yn", e.target.value); setShowRefresh(false);}} onKeyPress={(e) => handleKeyPress(e)} >
              <option value="" >전체</option>
              <option value="Y" >인증</option>
              <option value="N" >미인증</option>
            </Input> 
          </div>
          <Button onClick={(e) => handleSearch(e)}>검색</Button>
        </InputGroup>
      </Col>
      {
        showRefresh ? 
          <Col sm={3} style={{padding: 0, display: 'flex', alignItems:'center'}}>
            {/* <Button outline onClick={(e) => {props.searchList(1, props.search.searchData, 'N'); setRefreshOption(true)}}><FontAwesomeIcon icon={faRedoAlt} className={refreshOption ? 'refresh-rotate' : ''} /></Button> */}
            <div className='RefreshOption-toggle' style={{marginRight: '10px'}}>
                <Input type="radio" id='onRefreshOption' name="refreshOption" value={true} checked={refreshOption} onChange={(e) => setRefreshOption(true)} />
                <Label for='onRefreshOption'>on</Label>
                <Input type="radio" id='offRefreshOption' name="refreshOption" value={false} checked={!refreshOption} onChange={(e) =>setRefreshOption(false)} />
                <Label for='offRefreshOption'>off</Label>
            </div>
            {
              props.refresh && refreshOption ? 
                <div style={{display: 'flex'}}>
                  <FontAwesomeIcon icon={faRedoAlt} className={'refresh-rotate'} size='lg' color='#9d9d9d' />
                  <span style={{marginLeft: '8px'}}>5 min..</span>
                </div>
              : 
                <div>
                  <span style={{fontStyle: 'italic'}}>last update : {props.updateTime}</span>
                </div>
            }
            {/* <div style={{display: 'flex'}}>
              <FontAwesomeIcon icon={faRedoAlt} className={'refresh-rotate'} size='lg' color='#9d9d9d' />
              <span style={{marginLeft: '8px'}}>1 min..</span>
            </div> */}
          </Col>
          : null
      }
      
    </FormGroup>
  );

}

export default SearchAuthCentrix;