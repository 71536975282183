import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { Button, Form, Input } from 'reactstrap';
import '../css/Login.css';
import { useState } from 'react';

import {setCookie} from '../ReactCookie'
import axios from 'axios'

import { withRouter, Redirect} from 'react-router-dom'
import { Container } from "reactstrap";

function Login  ({ authenticated, location, ...props })  {
    const [admin_id, setAdmin_id] = useState("");
    const [admin_pwd, setAdmin_pwd] = useState("");
    const [user_agent, setUser_agent] = useState("");


    function handleKeyPress(e) {
       
        const userAgent = navigator.userAgent
        setUser_agent(userAgent);

        if (e.key === "Enter") {
            submit();
        } 
      }

      function addUserAgent(){
        const userAgent = navigator.userAgent
        setUser_agent(userAgent);
        submit();
      }

    function submit() {

        //const userAgent = navigator.userAgent
        //setUser_agent(userAgent);

        //sessionStorage.setItem("isAuthorized",true);
        //history.push("/");
        if(admin_id===''){
            alert('아이디를 입력해 주세요');
            return;
        }

        if(admin_pwd===''){
            alert('비밀번호를 입력해 주세요');
            return;
        }

        let user = {
            admin_id,
            admin_pwd,
            user_agent
        }

        //console.log("user",user);
        axios.post(process.env.REACT_APP_PUBLIC_SERVER + '/mgr/adm/login',user ,        
           { headers: {
                'Content-Type': 'application/json'
            }}           
        
        ) 
            .then(res => {
                if(res.data.res_code===200){
                    setCookie('access_token',res.data.auth.acc_token , { path: '/' });
                    setCookie('corp_idx',res.data.auth.corp_idx , { path: '/' });
                    setCookie('line_idx',res.data.auth.line_idx , { path: '/' });
                    setCookie('login_id',res.data.auth.login_id , { path: '/' });
                    setCookie('login_name',res.data.auth.login_name , { path: '/' });
                    setCookie('auth_type',res.data.auth.auth_type , { path: '/' });
                    setCookie('acc_auth',res.data.auth.acc_auth , { path: '/' });
                    setCookie('exe_auth',res.data.auth.exe_auth , { path: '/' });
                    setCookie('group_name',res.data.auth.group_name , { path: '/' });
                    
                    props.loginFunc(res.data.auth.acc_token);                    
                    props.history.push('/main');
                }else{
                    alert(res.data.res_msg);
                    return;
                }
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }else if (error.request) {
                console.log(error.request);
            }else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                console.log('Error', error.message);
            }
        });
       
    }

    //로그인 정보가 있으면  로 보내줌
    const { from } = location.state || { from: { pathname: "./" } };        

    if (authenticated) return <Redirect to={from} />;

    return (
        <div className="ui middle aligned center aligned grid" style={{ margin:'auto' }}>
            <div className="form-main">
                <h4 className="form-signin-heading mb-4  text-center">IQ200 녹취클라우드 관리자 로그인</h4>                
                <form className="ui form">                    
                    <div className="field">
                        <label>아이디</label>
                        <Input type="text" id="admin_id" className=" mb-2 form-control form-control-lg" placeholder="" required autoFocus value={admin_id}  onChange={(e) => setAdmin_id(e.target.value)}/>                
                    </div>
                    <div className="field">
                        <label>비밀번호</label>
                        <Input type="password" id="admin_pwd" className=" mb-2 form-control form-control-lg" placeholder="" required value={admin_pwd} onChange={(e) => setAdmin_pwd(e.target.value)} onKeyPress={(e) => handleKeyPress(e)} />                
                    </div>
                
                    
                <div className="text-end">
                    <Button className="ui fluid large teal submit button" type="button" onClick={addUserAgent}>로그인</Button>                        
                </div>                    
            </form>
            </div>
        </div>
    );

}
//onPress={Actions.Home}
export default withRouter(Login);