import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Switch, Redirect , Route, useHistory, useLocation } from "react-router-dom";

import SideBar from "./page/SideBar";
import Topbar from "./page/Topbar";

import { Container } from "reactstrap";
import classNames from "classnames";

import Login from './page/Login';
import AuthRoute from './page/AuthRoute';
import "./css/App.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import 'react-tippy/dist/tippy.css'

import {getCookie, deleteAllCookies, removeCookie, getToken, getAllCookies} from './ReactCookie'

import ContentBody from "./page/CententBody";
import CorpTable from "./page/corp/CorpTable";
import CorpDetail from "./page/corp/CorpDetail";
import LineTable from "./page/line/LineTable";
import LineDetail from "./page/line/LineDetail";

import CallbackTable from "./page/callback/CallbackTable";
import CallbackDetail from "./page/callback/CallbackDetail";

import RecordTable from "./page/record/RecordTable"
import RecordDetail from "./page/record/RecordDetail"

import SmsTable from "./page/sms/SmsTable"
import SmsDetail from "./page/sms/SmsDetail"
import ApiTable from "./page/api/ApiTable"
import ApiDetail from "./page/api/ApiDetail"

import StatMonthTable from "./page/stat/StatMonthTable"
import StatCorpTable from "./page/stat/StatCorpTable"

import AdminTable from "./page/admin/AdminTable"
import AdminDetail from "./page/admin/AdminDetail"

import AgentTable from "./page/agent/AgentTable"
import AgentDetail from "./page/agent/AgentDetail"

import LogTable from "./page/log/LogTable"
import LogDetail from "./page/log/LogDetail"

import ErrLogTable from "./page/errlog/ErrLogTable"
import ErrLogDetail from "./page/errlog/ErrLogDetail"

import AuthCentrixTable from "./page/authcentrix/AuthCentrixTable";

import NotFound from './page/NotFound';

import Header from './page/Header';
import Footer from './page/Footer';


function App() {

  const [user, setUser] = useState(null);  
  const authenticated = user != null;  

  //let acc_token = getCookie("access_token");
  const [cookie_info, setCookie_info] = useState([]);
  //const [acc_token, setAcc_token] = useState(getCookie("access_token"));

  let acc_token = getCookie("access_token");

  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const { pathname } = useLocation();
  
  const [ locationKeys, setLocationKeys ] = useState([])
  const history = useHistory();

  useEffect(() => {
    return history.listen(location => {

    if (history.action === 'PUSH') {
      setLocationKeys([ location.key ])
    }

    if (history.action === 'POP') {
      if (locationKeys[1] === location.key) {
        setLocationKeys(([ _, ...keys ]) => keys)

        // Handle forward event

      } else {
        setLocationKeys((keys) => [ location.key, ...keys ])

        // Handle back event

      }
    }
  })
}, [ locationKeys])
  
  useEffect(() => {

    if (typeof acc_token !== "undefined" && acc_token !== null && acc_token !== '') {

      setUser(acc_token);
      setCookie_info(getToken(acc_token));
      console.log("토큰 존재 여부 확인");
      
      const iat = parseInt(getToken(acc_token).iat + "000");
      const exp = parseInt(getToken(acc_token).exp + "000");
      
      if (Date.now() > exp) {
        console.log("토큰 유효시간 만료")        
        removeCookie("access_token");
        setUser();
        history.push('/login');
      }
    } else {
      console.log("토큰 없음" + getCookie("access_token"))
    }
  }, [acc_token]);    

  const loginFunc = (access_token) => {
    setCookie_info(getToken(access_token));
    setUser(access_token);    
    //history.push('/main')    
  }  

  const logoutFunc = () => {
    setUser(null);    
    deleteAllCookies();
    //removeCookie("access_token")
    //window.location.replace('/admin/login');
    history.push('/login');
  }

  //const { from } = pathname.state || { from: { pathname: "./" } };

  return (    
    <div className="App wrapper">    
      {/* 
        서브도메인으로 앞에 adm. 이 붙었을땐 아래가 필요가 없었는데 테스트서버에서 살릴땐 이게 필요하다
       */}
      {/* <Router basename="admin"> */}
      <Router basename="">
        {/*
        {acc_token === '' || typeof acc_token === 'undefined' ? <Redirect to="/login" /> : <Redirect to="/"/>}
        {acc_token === '' || typeof acc_token === 'undefined' ? <Redirect to="/login" /> : <Redirect to={`${pathname}`}/>}
        {acc_token === '' || typeof acc_token === 'undefined' ? <Redirect to="/login" /> : <Redirect to="/" />}
        */}
        
        {authenticated === false ? <Redirect to="/login" /> : <></>}
        {authenticated === false ? <></> :
          <>
            <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />            
          </>
        }
        <Switch>
          <Route
                path="/login"
                render={props => (
                  <Login authenticated={authenticated} loginFunc={loginFunc} {...props} />
            )}
          />            

           <>
            <Container fluid className={classNames("content", { "is-open": sidebarIsOpen })}> 
                    <Topbar toggleSidebar={toggleSidebar} logoutFunc={logoutFunc} />                
                    <AuthRoute exact authenticated={authenticated} path="/main" render={props => <ContentBody cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/corps" render={props => <CorpTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/corp/:corp_idx" render={props => <CorpDetail cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/lines" render={props => <LineTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/line/:line_idx" render={props => <LineDetail {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/callbacks" render={props => <CallbackTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/callback/:callback_idx" render={props => <CallbackDetail cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/records" render={props => <RecordTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/record/:record_idx" render={props => <RecordDetail cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/smss" render={props => <SmsTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/sms/:sms_idx" render={props => <SmsDetail cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/apis" render={props => <ApiTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/api/:api_idx" render={props => <ApiDetail cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/stat_month" render={props => <StatMonthTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/stat_corps" render={props => <StatCorpTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/adms" render={props => <AdminTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/adm/:admin_idx" render={props => <AdminDetail cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/agents" render={props => <AgentTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/agent/:agent_idx" render={props => <AgentDetail cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/logs" render={props => <LogTable cookie={cookie_info} user={user} {...props} />} />
                    <AuthRoute exact authenticated={authenticated} path="/log/:log_idx" render={props => <LogDetail cookie={cookie_info} user={user} {...props} />} />           
                    <AuthRoute exact authenticated={authenticated} path="/errlogs" render={props => <ErrLogTable cookie={cookie_info} user={user} {...props} />} />
                    {/* <AuthRoute exact authenticated={authenticated} path="/errlog/:log_idx" render={props => <ErrLogDetail cookie={cookie_info} user={user} {...props} />} /> */}
                    <AuthRoute exact authenticated={authenticated} path="/authcentrixs" render={props => <AuthCentrixTable cookie={cookie_info} user={user} {...props} />} />
              </Container>          
            </>
            
         {/* <Route component={NotFound} />  */}
        </Switch>    
        
      </Router>
    </div>      
  );
}

export default App;